<div class="footer-newsletter">
    <h5 class="footer-newsletter__title">SUBSCRÍBETE A NUESTRO BOLETÍN</h5>
    <div class="footer-newsletter__text">
        Enviaremos a tu correo todas nuestras noticias, articulos, promociones y novedades.
    </div>

    <form action="" class="footer-newsletter__form">
        <label class="sr-only" for="footer-newsletter-address">Correo Electronico</label>
        <input type="text" class="footer-newsletter__form-input form-control" id="footer-newsletter-address" placeholder="Correo Electronico...">

        <button class="footer-newsletter__form-button btn btn-primary">ENVIAR</button>
    </form>

    <div class="footer-newsletter__text footer-newsletter__text--social">
        REDES SOCIALES
    </div>

    <app-social-links class="footer-newsletter__social-links" shape="circle"></app-social-links>
</div>