import {Injectable} from '@angular/core';
import {URL_SERVICES, URL_SERVICES_IMAGES} from '../../../config/config';
import {BehaviorSubject} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SliderService {
    URL_API = URL_SERVICES;
    public sliders: any;
    public elements = [];
    public sliderResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.sliders);

    constructor(private http: HttpClient) {
        this.loadSliders();
    }

    async loadSliders() {
        const resp = await this.getSlidersBySucursal();
        if (resp) {
            this.sliderResource.next(resp);
        }
    }

    getSliders(): Promise<any> {
        return new Promise((resolve, rejects) => {
            this.http.get(`${this.URL_API}getAllSliders`)
                .toPromise()
                .then((resp: any) => {
                    this.elements = [];
                    for (const slidersMapElement of resp) {
                        console.log('URL:',URL_SERVICES_IMAGES + '../..' + slidersMapElement.imagen);
                        const slide = {
                            nombre: slidersMapElement.nombre,
                            imagen: URL_SERVICES_IMAGES + '../..' + slidersMapElement.imagen
                        }
                        this.elements.push(slide);
                    }
                    resolve(this.elements);
                })
                .catch(this.handleError)
        })
    }

    getSlidersBySucursal(): Promise<any> {
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;
        const sucursal = user?.sucursal ?? 1;
        return new Promise((resolve, rejects) => {
            this.http.get(`${this.URL_API}getSlidersBySucursal/${sucursal}`)
                .toPromise()
                .then((resp: any) => {
                    this.elements = [];
                    for (const slidersMapElement of resp) {
                        const slide = {
                            nombre: slidersMapElement.nombre,
                            imagen: URL_SERVICES_IMAGES + slidersMapElement.imagenw ?? slidersMapElement.imagen
                        }
                        this.elements.push(slide);
                    }
                    resolve(this.elements);
                })
                .catch(this.handleError)
        })
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
