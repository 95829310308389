import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { URL_SERVICES } from '../../../config/config';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  URL_API = URL_SERVICES;
  public categoriesList: any;
  public categoryResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.categoriesList)

  constructor(
    private http: HttpClient
  ) {
    this.loadFirstCats()
  }

  async loadFirstCats() {
    const resp = await this.getCategories();
    if (resp) {
      this.categoryResource.next(resp);
    }
    
  }

  getCategories(): Promise<any> {
    return new Promise((resolve, rejects) => {
      this.http.get(`${this.URL_API}categories-api`)
        .toPromise()
        .then((resp: any) => {
          resolve(resp)})
        .catch(this.handleError)
        

    })
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
 }

}
