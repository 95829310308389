import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Category } from '../interfaces/category';
import { HttpClient } from '@angular/common/http';
import { Brand } from '../interfaces/brand';
import { Product } from '../interfaces/product';
import { ProductsList } from '../interfaces/list';
import { SerializedFilterValues } from '../interfaces/filter';
import {
    getBestsellers,
    getFeatured,
    getLatestProducts,
    getProduct,
    getRelatedProducts,
    getSpecialOffers,
    getTopRated,
    getShopCategoriesBySlugs,
    getShopCategoriesTree,
    getShopCategory,
    getBrands,
    getProductsList,
} from '../../../fake-server';
import { getSuggestions } from 'src/fake-server/database/products';
import { URL_SERVICES } from '../../config/config';
import { CompanyService } from 'src/app/shared/services/company.service';
import { take } from 'rxjs/operators';
import { SharedService } from '../services/shared.service';

export interface ListOptions {
    page?: number;
    limit?: number;
    sort?: string;
    filterValues?: SerializedFilterValues;
}

const URL_API = URL_SERVICES;
@Injectable({
    providedIn: 'root'
})
export class ShopService {

    URL_API = URL_SERVICES;
    private productList: any;
    public productTermResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.productList);
    private productServiceList: any;
    public pagination: any = {
        meta: {},
        links:{}
    };
    constructor(
        private http: HttpClient,
        private _companyService: CompanyService,
        private sharedService: SharedService
    ) { }

    /**
     * Returns category object by slug.
     *
     * @param slug - Unique human-readable category identifier.
     */
    getCategory(slug: string): Observable<Category> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories/power-tools.json
         *
         * where:
         * - power-tools = slug
         */
        // return this.http.get<Category>(`https://example.com/api/shop/categories/${slug}.json`);

        // This is for demonstration purposes only. Remove it and use the code above.
        return getShopCategory(slug);
    }

    /**
     * Returns a category tree.
     *
     * @param parent - If a parent is specified then its descendants will be returned.
     * @param depth  - Maximum depth of category tree.
     */
    getCategories(): Observable<any> {
        return this.http.get(`${URL_API}categories-api`,{responseType:'json'});
    }

    /**
     * Returns a category tree.
     *
     * @param parent - If a parent is specified then its descendants will be returned.
     * @param depth  - Maximum depth of category tree.
     */
    getAllCategories(): Observable<any> {
        return this.http.get(`${URL_API}categories-api-all`,{responseType:'json'});
    }
    //getCategories(parent: Partial<Category> = null, depth: number = 0): Observable<Category[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories.json?parent=latest-news&depth=1
         *
         * where:
         * - parent = parent.slug
         * - depth  = depth
         */
        // const params: {[param: string]: string} = {
        //     parent: parent.slug,
        //     depth: depth.toString(),
        // };
        //
        // return this.http.get<Category[]>('https://example.com/api/shop/categories.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        //return getShopCategoriesTree(parent ? parent.slug : null, depth);
    //}

    getBrands(): Observable<any> {
        return this.http.get(`${URL_API}brands-api`,{responseType:'json'});
    }

    /**
     * Returns an array of the specified categories.
     *
     * @param slugs - Array of slugs.
     * @param depth - Maximum depth of category tree.
     */
    getCategoriesBySlug(slugs: string[], depth: number = 0): Observable<Category[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/categories.json?slugs=power-tools,measurement&depth=1
         *
         * where:
         * - slugs = slugs.join(',')
         * - depth = depth
         */
        // const params: {[param: string]: string} = {
        //     slugs: slugs.join(','),
        //     depth: depth.toString(),
        // };
        //
        // return this.http.get<Category[]>('https://example.com/api/shop/categories.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getShopCategoriesBySlugs(slugs, depth);
    }

    /**
     * Returns paginated products list.
     * If categorySlug is null then a list of all products should be returned.
     *
     * @param categorySlug         - Unique human-readable category identifier.
     * @param options              - Options.
     * @param options.page         - Page number (optional).
     * @param options.limit        - Maximum number of items returned at one time (optional).
     * @param options.sort         - The algorithm by which the list should be sorted (optional).
     * @param options.filterValues - An object whose keys are filter slugs and values ​​are filter values (optional).
     */
    getProductsLista(categorySlug?: string|null): Promise<any> {
        const sucursal = this._companyService.user.sucursal;
        const user = this._companyService.user.id;
        const apiurl = categorySlug ? `products-by-category/${categorySlug}/${sucursal}/${user}` : 'get-products-sucursal/' + sucursal
        return new Promise((resolve, reject) => {
            this.http.get(`${URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(err => console.log(err)
                )
        })
    }

    getProductsServiceLista(categorySlug?: string|null): Promise<any> {
        const user = this._companyService.user.id;
        const apiurl = categorySlug ? `products-by-category/${categorySlug}/${user}` : 'products-conversiones'
        return new Promise((resolve, reject) => {
            this.http.get(`${URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(err => console.log(err)
                )
        })
    }

    getProductsCapacitaciones(): Promise<any>{

        console.log('aaaaaaaaaaaaaaaaaaa');
        const apiurl = 'trainings';
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    console.log(resp);
                    resolve(resp);
                })
                .catch(this.handleError)
        })

    }

    getProductsServices(): Promise<any>{

        console.log('aaaaaaaaaaaaaaaaaaa');
        const apiurl = 'products-conversiones';
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    console.log(resp);
                    resolve(resp);
                })
                .catch(this.handleError)
        })

    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
     }

     getProductsListService(categorySlug: string|null, options: ListOptions): Observable<ProductsList> {
        return getProductsList(categorySlug, options);
     }

    getProductsList(categorySlug: string|null, options: ListOptions): Observable<ProductsList> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products.json?category=screwdriwers&page=2&limit=12&sort=name_desc&filter_price=500-1000
         *
         * where:
         * - category     = categorySlug
         * - page         = options.page
         * - limit        = options.limit
         * - sort         = options.sort
         * - filter_price = options.filterValues.price
         */

        // const URL = 'http://localhost/apieneralt/public/api/products-api';

        // const params: {[param: string]: string} = {};

        // if (categorySlug) {
        //     params.category = categorySlug;
        // }
        // if ('page' in options) {
        //     params.page = options.page.toString();
        // }
        // if ('limit' in options) {
        //     params.limit = options.limit.toString();
        // }
        // if ('sort' in options) {
        //     params.sort = options.sort;
        // }
        // if ('filterValues' in options) {
        //     Object.keys(options.filterValues).forEach(slug => params[`filter_${slug}`] = options.filterValues[slug]);
        // }

        // getProductsList(categorySlug, options).subscribe( (resp) => {
        //     console.log(resp);
        // });

        // this.http.get<ProductsList>(URL, {params}).subscribe( (resp) => {
        //     console.log(resp);
        // });


        // return this.http.get<ProductsList>(URL, {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getProductsList(categorySlug, options);
    }

    getProduct(productSlug: string): Observable<Product> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/products/electric-planer-brandix-kl370090g-300-watts.json
         *
         * where:
         * - electric-planer-brandix-kl370090g-300-watts = productSlug
         */
        // return this.http.get<Product>(`https://example.com/api/products/${productSlug}.json`);

        // This is for demonstration purposes only. Remove it and use the code above.
        return getProduct(productSlug);
    }

    /**
     * Returns popular brands.
     */
    getPopularBrands(): Observable<Brand[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/brands/popular.json
         */
        // return this.http.get<Brand[]>('https://example.com/api/shop/brands/popular.json');

        // This is for demonstration purposes only. Remove it and use the code above.
        return getBrands();
    }

    getBestsellers(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/bestsellers.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/bestsellers.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getBestsellers(limit);
    }

    getTopRated(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/top-rated.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/top-rated.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getTopRated(limit);
    }

    getSpecialOffers(limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/special-offers.json?limit=3
         *
         * where:
         * - limit = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/special-offers.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getSpecialOffers(limit);
    }

    getFeaturedProducts(categorySlug: string = null, limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/featured.json?category=screwdrivers&limit=3
         *
         * where:
         * - category = categorySlug
         * - limit    = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (category) {
        //     params.category = category;
        // }
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/featured.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getFeatured(categorySlug, limit);
    }

    getLatestProducts(categorySlug: string = null, limit: number = null): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/latest.json?category=screwdrivers&limit=3
         *
         * where:
         * - category = categorySlug
         * - limit    = limit
         */
        // const params: {[param: string]: string} = {};
        //
        // if (category) {
        //     params.category = category;
        // }
        // if (limit) {
        //     params.limit = limit.toString();
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/latest.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getLatestProducts(categorySlug, limit);
    }

    getRelatedProducts(product: Partial<Product>): Observable<Product[]> {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/shop/products/related.json?for=water-tap
         *
         * where:
         * - for = product.slug
         */
        // const params: {[param: string]: string} = {
        //     for: product.slug,
        // };
        //
        // return this.http.get<Product[]>('https://example.com/api/shop/products/related.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        return getRelatedProducts(product);
    }

    getSuggestions(query: string, limit: number, categorySlug: string = null, sucursal) {
        /**
         * This is what your API endpoint might look like:
         *
         * https://example.com/api/search/suggestions.json?query=screwdriver&limit=5&category=power-tools
         *
         * where:
         * - query = query
         * - limit = limit
         * - category = categorySlug
         */
        // const params: {[param: string]: string} = {query, limit: limit.toString()};
        //
        // if (categorySlug) {
        //     params.category = categorySlug;
        // }
        //
        // return this.http.get<Product[]>('https://example.com/api/search/suggestions.json', {params});

        // This is for demonstration purposes only. Remove it and use the code above.
        const data = {
            query,
            limit,
            categorySlug,
            sucursal
        }

        const user = this._companyService.user.id;
        const apiurl = 'get-products-search/' + data.query + '/' + user + '/' + data.categorySlug + '/' + data.limit +'/' + sucursal + '/' + 'a-z';
        return this.http.get(`${this.URL_API}${apiurl}`);
    }

    // getSearchTerm(term: string) {
    //     const sucursal = this._companyService.user.sucursal;
    //     let url = URL_API + 'get-products-search/' + term + '/' + 20000 + '/' + null + '/' + sucursal;
    //     this.http.get(url).pipe(take(1)).toPromise().then( (resp: any) => {
    //         console.log(resp);
    //         if (resp.data) {
    //             this.productList = resp.data;
    //             this.pagination = {links: resp.links, meta: resp.meta}
    //         } else {
    //             this.productList = resp
    //         }
    //         this.productTermResource.next(this.productList);
    //     });
    //     // return new Promise((resolve, reject) => {
    //     //     this.http.get(url)
    //         // .toPromise()
    //         // .then(resp => {
    //         //     console.log(resp);
    //         //     resolve(resp)
    //         // })
    //         // .catch(err => console.error(err)
    //         // )
    //     // })
    // }

    getSearchTerm(term: string, category) {
        console.log('getSearchTerm');

        const sucursal = this._companyService.user.sucursal; 
        const user = this._companyService.user.id;      
        // let url = URL_API + 'get-products-search/' + term + '/' + 20000 + '/' + null + '/' + sucursal + '/' + user;
        let url = URL_API + 'get-products-search/' + term + '/' + user + '/' + category + '/' + 20000 + '/' + sucursal + '/' + 'a-z' + `?page=${this.sharedService.pageActualSearch}`;
        return new Promise((resolve, reject) => {
                this.http.get(url)
                .toPromise()
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => console.error(err)
                )
            })
    }

    getSearchTermUrl(url: string, sort?) {
        console.log('getSearchTermUrl', url);
        // const sucursal = this._companyService.user.sucursal;        
        // let url = URL_API + 'get-products-search/' + term + '/' + 20000 + '/' + null + '/' + sucursal;
        return new Promise((resolve, reject) => {
                this.http.get(url)
                .toPromise()
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => console.error(err)
                )
            })
    }
}
