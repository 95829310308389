<div class="product-gallery__featured">
    <!-- <button *ngIf="productLayout !== 'quickview'" class="product-gallery__zoom" (click)="openPhotoSwipe(currentItem)">
        <app-icon name="zoom-in-24" size="24"></app-icon>
    </button> -->
    <owl-carousel-o [options]="carouselOptions" (changed)="featuredCarouselTranslated($event)" appOwlPreventClick #featuredCarousel>
        <!--suppress AngularUndefinedBinding -->
        <ng-template *ngFor="let item of items" carouselSlide [id]="item.id">
            <div class="product-image product-image--location--gallery">
                <a appClick class="product-image__body" >
                    <!--
                    The data-width and data-height attributes must contain the size of a larger version
                    of the product image.

                    If you do not know the image size, you can remove the data-width and data-height
                    attribute, in which case the width and height will be obtained from the naturalWidth
                    and naturalHeight property of img.product-image__img.
                    -->
                    <img class="product-image__img" [src]="item.image | image:'products':idProduct" alt="product" #imageElement>
                    <!-- <img class="logo" src="assets/images/logos/logo_footer.png" alt="logo"> -->
                </a>
            </div>
        </ng-template>
    </owl-carousel-o>
</div>
<div class="product-gallery__carousel">
    <owl-carousel-o appOwlPreventClick [options]="thumbnailsCarouselOptions" [appFakeSlides]="items.length" #fakeSlides="appFakeSlides" #thumbnailsCarousel>
        <!--suppress AngularUndefinedBinding -->
        <ng-template *ngFor="let item of items" carouselSlide [id]="item.id">
            <div appClick class="product-image product-gallery__carousel-item" [class.product-gallery__carousel-item--active]="item === currentItem" (click)="onThumbnailImageClick(item)">
                <div class="product-image__body">
                    <img class="product-image__img product-gallery__carousel-image" [src]="item.image | image:'products':idProduct" alt="">
                </div>
            </div>
        </ng-template>
        <!--suppress AngularUndefinedBinding -->
        <ng-template *ngFor="let i of fakeSlides.slides" carouselSlide [id]="'fake-slide-'+i"></ng-template>
    </owl-carousel-o>
</div>
