import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { URL_SERVICES } from '../../config/config';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { map, filter, scan } from 'rxjs/operators';
import {BlockPopupComponent} from '../../modules/blocks/block-popup/block-popup.component';
import {ProductService} from '../../modules/shop/services/products.service';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {PopupLocalService} from '../../modules/header/services/popup-local.service';

@Injectable({
    providedIn: 'root'
})
export class CompanyService {

    public user;
    public token;
    srcImagen: any;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        public router: Router,
        private _modalService: NgbModal,
        public _popupService: PopupLocalService
    ) {
        this.loadStorage();
        console.log(this.user);
    }

    // Load user information in localstorage
  loadStorage() {

    if ( localStorage.getItem('token')) {
      this.token = localStorage.getItem('token');
      this.user = JSON.parse( localStorage.getItem('user') );
    } else {
      this.token = '';
      this.user = null;
    }

  }
  // Create company
  createCompany( company, role ) {

    console.log(company);

    const url = URL_SERVICES + 'users';

    company.role = role;

    return this.http.post( url, company )
  }

  getClientsByUser() {
    let url = URL_SERVICES + 'get-clients/' + this.user.id;
    url += '?token=' + this.token;

    return this.http.get( url );
  }

  isLoginCompany() {

    this.loadStorage();
    return ( this.token.length > 5 && this.user.role === 'Empresa' ) ? true : false;
  }

  isLoginClient() {

    this.loadStorage();
    return ( this.token.length > 5 && (this.user.role === 'Cliente' || 'Administrador') ) ? true : false;
  }

  loadUser() {
    let url = URL_SERVICES + 'users/' + this.user.id;
    url += '?token=' + this.token;

    return this.http.get( url );
  }

  getUser() {

    if( !this.user ) {
      this.router.navigate(['/cuenta/login']);
    }
    let url = URL_SERVICES + 'users/' + this.user.id;
    url += '?token=' + this.token;

    return this.http.get( url );
  }

  isLogin() {

    this.loadStorage();

    return ( this.token.length > 5) ? true : false;
  }

  getAddressSucursal() {
    if( !this.user ) {
      this.router.navigate(['/cuenta/login']);
    }
    let url = URL_SERVICES + 'get-address-sucursal/' + this.user.sucursal;
    // url += '?token=' + this.token;

    return this.http.get( url );
  }

    async getPopup() {
        this.srcImagen = await this._popupService.getPopup();
    }

  // login
  login( user ) {

    const url = URL_SERVICES + 'ingresar';

    return this.http.post( url, user ).subscribe( (resp: any) => {
                  const response = resp;
                  if(resp.user.role === 'Cliente' || resp.user.role === 'Vendedor') {
                    this.getPopup();
                    this.saveStorage( response.user.id, response.access_token, response.user );
                    this.user = response.user;
                    this.router.navigate(['/productos']);
                    setTimeout(() => {
                        const modalRef = this._modalService.open(BlockPopupComponent, {
                            centered: true,
                            size: 'lg',
                            backdrop: 'static'
                        });

                        modalRef.componentInstance.srcImagen = this.srcImagen;
                        modalRef.result.then();
                    },1000);
                    return true;
                  }else{
                    this.toastr.error(`No posee permisos para ingresar aqui`);
                    return;
                  }
                },(error: any) => {
                    this.toastr.error(`Correo o contraseña invalida`);
                  });

  }

  // Logout
  logout() {
    this.user = null;
    this.token = '';

    localStorage.removeItem('token');
    localStorage.removeItem('user');

    const url = URL_SERVICES + 'logout';
    this.http.get( url );
    this.toastr.success(`Se cerró la sesión`);
    this.router.navigate(['']);
  }

  // Save user information in localstorage
  saveStorage( id, token: string, user ) {

    localStorage.setItem('id', id );
    localStorage.setItem('token', token );
    localStorage.setItem('user', JSON.stringify(user) );

    this.token = token;
  }

  updateUser(data) {

    let url = URL_SERVICES + 'users/' + this.user.id;
    url += '?token=' + this.token;

    console.log(data);

    return this.http.put( url, data ).subscribe( (response: any) => {
      this.saveStorage( response.id, this.token, response );
      this.toastr.success('El usuario se actualizo correctamente');
      // this.user = response.user;
      return response;

    }, (error) => {
      console.log(error);
      this.toastr.error(error.error.error);
    });
  }

  // Save image profile
  saveImageProfile( formData ) {

    let url = URL_SERVICES + 'users/picture-profile/' + this.user.id;
    url += '?token=' + this.token;

    console.log(formData);

    return this.http.post(url, formData).subscribe( (resp: any) => {
        const response = resp;
        this.toastr.success(`La imagen se actualizo correctamente`);
        this.saveStorage( response.id, this.token, response );
      }, (error) => {
        this.toastr.error(`Error subiendo imagen`);
      });
  }

  recoverPass( user ) {

    const url = URL_SERVICES + 'reset-password';
    // url += '?token=' + this.token;

    return this.http.post( url, user )
                    .subscribe( (resp: any) => {
                      this.toastr.success(`Se ha enviado un correo a su bandeja de entrada para restablecer su contraseña`);
                    }, (error) => {
                      this.toastr.error(`Error recuperando contraseña`);
                    });

  }

  resetPassword( formReset ) {

    const url = URL_SERVICES + 'password/reset';

    return this.http.post( url, formReset )
                    .subscribe( (resp: any) => {
                      this.toastr.success(`La contraseña se restauró con exito`);
                    }, (error) => {
                      this.toastr.error(`No se ha podido restaurar la contraseña`);
                    });
  }
}
