import {Injectable} from '@angular/core';
import {URL_SERVICES, URL_SERVICES_IMAGES} from '../../../config/config';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class PopupLocalService {
    URL_API = URL_SERVICES;
    public popup: any;
    public popupResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.popup);

    constructor(private http: HttpClient) {
        this.loadPopup();
    }

    async loadPopup() {
        const resp = await this.getPopup();
        if (resp) {
            this.popupResource.next(resp);
        }
    }

    getPopup(): Promise<any> {
        return new Promise((resolve, rejects) => {
            this.http.get(`${this.URL_API}getPopup`)
                .toPromise()
                .then((resp: any) => {
                    const srcImagen = URL_SERVICES_IMAGES  + resp.imagen;
                    resolve(srcImagen);
                })
                .catch(this.handleError)
        })
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
