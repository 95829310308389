<!-- .block-features -->
<div class="block block-features block-features--layout--{{ layout }}">
    <div class="container">
        <div class="block-features__list">
            <div class="block-features__item domicilio">
                <div class="block-features__icon">
                    <img src="assets/images/icons/icon_entrega.png" alt="">
                    <!-- <app-icon name="fi-free-delivery-48" size="48"></app-icon> -->
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">ENTREGAS A DOMICILIO</div>
                    <div class="block-features__subtitle">Realiza tu pedido y recibelo en tu negocio</div>
                </div>
            </div>
            <!-- <div class="block-features__divider"></div> -->
            <div class="block-features__item sucursales">
                <div class="block-features__icon">
                    <img src="assets/images/icons/icon_tienda.png" alt="">
                    <!-- <app-icon name="fi-free-delivery-48" size="48"></app-icon> -->
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">SUCURSALES</div>
                    <div class="block-features__subtitle">Contamos con sucursales a tu disposición</div>
                </div>
            </div>
            <!-- <div class="block-features__divider"></div> -->
            <div class="block-features__item asesor">
                <div class="block-features__icon">
                    <app-icon name="fi-24-hours-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">ASESOR DE VENTAS</div>
                    <div class="block-features__subtitle">Asesoramos a nuestros clientes eficazmente</div>
                </div>
            </div>
            <!-- <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-payment-security-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">100% SEGURO</div>
                    <div class="block-features__subtitle">Compra asegurada</div>
                </div>
            </div>
            <div class="block-features__divider"></div>
            <div class="block-features__item">
                <div class="block-features__icon">
                    <app-icon name="fi-tag-48" size="48"></app-icon>
                </div>
                <div class="block-features__content">
                    <div class="block-features__title">PROMOCIONES</div>
                    <div class="block-features__subtitle">Pregunta por nuestras promociones</div>
                </div>
            </div> -->

        </div>
    </div>
</div>
<!-- .block-features / end -->