import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CompanyService } from '../../../../shared/services/company.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {

    forma: FormGroup;
    email: string;
    loginForm: boolean = true;
    eye: string = 'fa fa-eye-slash';
    inputType: string = 'password';
    visible = false;

    constructor(
        private _companyService: CompanyService,
        private toastr: ToastrService,
    ) { }

    ngOnInit() {

        this.forma = new FormGroup({
          email: new FormControl( null , Validators.required ),
          password: new FormControl( null , Validators.required ),
        });

      }

      back() {
        this.loginForm = true;
      }

      recover() {
        this.loginForm = false;
      }

      login() {

        if ( this.forma.invalid ) {
          this.toastr.error(`Por favor, verifique su correo y contraseña`);
          return;
        }
        this._companyService.login( this.forma.value );

      }

      viewPassword() {
        if (this.visible) {
          this.inputType = 'password';
          this.visible = false;
        } else {
          this.inputType = 'text';
          this.visible = true;}
      }
}
