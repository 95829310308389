<div class="modal-body" ngbAutofocus>
    <div class="row">
        <div class="col-12">
            <img [src]="srcImagen" style="width: 100%;">
        </div>
    </div>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="onClose()" rippleEffect>Cerrar</button>
</div>
