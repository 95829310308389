<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery" *ngIf="product.pictures">
            <div class="product__info">

                <p [innerHTML]="product.description"></p>

                <!-- <div class="product__wishlist-compare">
                    <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Favoritos" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">
                        <app-icon name="wishlist-16" size="16"></app-icon>
                    </button>
                    <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Comparar" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">
                        <app-icon name="compare-16" size="16"></app-icon>
                    </button>
                </div>

                <h1 class="product__name">{{ product.title }}</h1>
                <div class="product__rating">
                    <div class="product__rating-stars">
                        <app-rating [value]="product.rating"></app-rating>
                    </div>
                    <div class="product__rating-legend">
                        <a href="" appClick>{{ product.reviews }} Comentarios</a><span>/</span><a href="" appClick>Escribir un comentario</a>
                    </div>
                </div>
                <div class="product__description">
                    {{product.shortDetail}}
                </div>
                <ul class="product__features">
                    <li>Speed: 750 RPM</li>
                    <li>Power Source: Cordless-Electric</li>
                    <li>Battery Cell Type: Lithium</li>
                    <li>Voltage: 20 Volts</li>
                    <li>Battery Capacity: 2 Ah</li>
                </ul>
                <ul class="product__meta">
                    <li class="product__meta-availability">Disponible: <span class="text-success">En Stock {{ product.stock }} piezas</span></li>
                    <li>Categoría: <a href="" appClick>{{product.category}}</a></li>
                    <li>SKU: {{ product.sku }}</li>
                </ul> -->
            </div>
            <!-- .product__info / end -->
            <!-- .product__sidebar -->

        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->

        <!-- .product__end -->
        <div class="product__footer">
            <!-- <div class="product__tags tags">
                <div class="tags__list">
                    <a href="" appClick>Mounts</a>
                    <a href="" appClick>Electrodes</a>
                    <a href="" appClick>Chainsaws</a>
                </div>
            </div> -->

            <div class="product__share-links share-links">
                <ul class="share-links__list">
                    <li class="share-links__item share-links__item--type--like"><a href="" appClick>Like</a></li>
                    <li class="share-links__item share-links__item--type--tweet"><a href="" appClick>Tweet</a></li>
                    <!-- <li class="share-links__item share-links__item--type--pin"><a href="" appClick>Pin It</a></li>
                    <li class="share-links__item share-links__item--type--counter"><a href="" appClick>4K</a></li> -->
                </ul>
            </div>
        </div>
    </div>
</div>