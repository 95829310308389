import {Component, Inject, Input, PLATFORM_ID} from '@angular/core';
import {Product} from '../../interfaces/product';
import {FormControl} from '@angular/forms';
import {CartService} from '../../services/cart.service';
import {WishlistService} from '../../services/wishlist.service';
import {CompareService} from '../../services/compare.service';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

export type ProductLayout = 'standard' | 'sidebar' | 'columnar' | 'quickview';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrls: ['./product.component.scss']
})
export class ProductComponent {
    @Input() layout: ProductLayout;

    @Input() product: any;

    @Input() tab: 'description'|'specification'|'reviews' = 'description';

    quantity: FormControl = new FormControl(1);

    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    url;
    user: any;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private cart: CartService,
        private wishlist: WishlistService,
        private compare: CompareService,
        public router: Router,
        private toastr: ToastrService
    ) {
        this.url = this.router.url;
    }

    ngOnInit(): void {
        this.user = JSON.parse( localStorage.getItem('user') );
        console.log('Producto only:',this.product);
    }

    filteredSucursals() {
        return this.product.sucursal_info.filter(sucursal =>
          !sucursal.name.toLowerCase().includes('oro') &&
          !sucursal.name.toLowerCase().includes('plata')
        );
      }

    addToCartOdl(): void {
        if (this.product.stock < this.quantity.value) {
            this.toastr.error('No disponible - No contamos con existencia');
            return;
        }
        if (!this.addingToCart && this.product && this.quantity.value > 0) {
            this.addingToCart = true;

            if (this.product.mayoreo_2 > 0 &&
                this.quantity.value >= this.product.mayoreo_2 &&
                this.quantity.value < this.product.mayoreo_3 &&
                this.product.mayoreo_3 > 0 && this.quantity.value < this.product.mayoreo_3) {
                this.product.current_price = this.product.price_2;

            } else if (this.product.mayoreo_2 > 0 &&
                this.quantity.value >= this.product.mayoreo_2 &&
                this.quantity.value < this.product.mayoreo_3 &&
                this.product.mayoreo_3 == 0) {
                this.product.current_price = this.product.price_2;

            } else if (this.product.mayoreo_3 > 0 &&
                this.quantity.value >= this.product.mayoreo_3 &&
                this.product.mayoreo_4 > 0 && this.quantity.value < this.product.mayoreo_4) {
                this.product.current_price = this.product.price_3;

            } else if (this.product.mayoreo_3 > 0 &&
                this.quantity.value >= this.product.mayoreo_3 &&
                this.product.mayoreo_4 == 0) {
                this.product.current_price = this.product.price_3;

            } else if (this.product.mayoreo_4 > 0 &&
                this.quantity.value >= this.product.mayoreo_4) {
                this.product.current_price = this.product.price_4;
            } else {
                this.product.current_price = this.product.price;
            }

            this.cart.add(this.product, this.quantity.value).subscribe({complete: () => this.addingToCart = false});
        }
    }

    getDisplayPrice(): { price: number, minQuantity: number | null } {
         console.log("ProductHere: ",this.product);
        if (this.user.price_list === 'oro') {
            return { price: this.product.price_gold, minQuantity: null };
        }
        if (this.user.price_list === 'plata') {
            return { price: this.product.price_silver, minQuantity: null };
        }

        return this.getBestPrice();
    }

    private getBestPrice(): { price: number, minQuantity: number | null } {
        const quantityValue = this.quantity.value;
        const stock = this.product.stock;

        if (stock === 0) {
            return { price: this.product.price, minQuantity: null };
        }

        const prices = [
            { price: this.product.price_4, quantity: this.product.mayoreo_4 },
            { price: this.product.price_3, quantity: this.product.mayoreo_3 },
            { price: this.product.price_2, quantity: this.product.mayoreo_2 },
            { price: this.product.price, quantity: 1 }
        ];

        for (const priceInfo of prices) {
            if (priceInfo.price && priceInfo.quantity && priceInfo.quantity <= stock) {
                if (quantityValue >= priceInfo.quantity || quantityValue === 0) {
                    return { price: priceInfo.price, minQuantity: priceInfo.quantity };
                }
            }
        }

        return { price: this.product.price, minQuantity: null };
    }

    getPriceClass(): string {
        if (this.user.price_list === 'oro') return 'text-gold';
        if (this.user.price_list === 'plata') return 'text-silver';
        const { price } = this.getDisplayPrice();
        if (price === this.product.price_4) return 'text-danger';
        if (price === this.product.price_3) return 'text-warning';
        if (price === this.product.price_2) return 'text-success';
        return '';
    }

    addToCart(): void {
        if (this.product.stock < this.quantity.value) {
          this.toastr.error('No disponible - No contamos con existencia');
          return;
        }

        if (this.addingToCart) {
          return;
        }

        if (!this.addingToCart && this.product && this.quantity.value > 0) {
          this.addingToCart = true;

          if (this.product.mayoreo_4 > 0 && this.quantity.value >= this.product.mayoreo_4) {
            this.product.current_price = this.product.price_4;
          } else if (this.product.mayoreo_3 > 0 && this.quantity.value >= this.product.mayoreo_3) {
            this.product.current_price = this.product.price_3;
          } else if (this.product.mayoreo_2 > 0 && this.quantity.value >= this.product.mayoreo_2) {
            this.product.current_price = this.product.price_2;
          } else {
            this.product.current_price = this.product.price;
          }

          this.cart.add(this.product, this.quantity.value).subscribe({
            complete: () => {
              this.addingToCart = false;
            }
          });
        }
      }

    addToWishlist(): void {
        if (!this.addingToWishlist && this.product) {
            this.addingToWishlist = true;

            this.wishlist.add(this.product).subscribe({complete: () => this.addingToWishlist = false});
        }
    }

    addToCompare(): void {
        if (!this.addingToCompare && this.product) {
            this.addingToCompare = true;

            this.compare.add(this.product).subscribe({complete: () => this.addingToCompare = false});
        }
    }

    changeQuantity(e) {
        console.log(e);
    }
}
