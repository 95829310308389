import { Post } from '../app/shared/interfaces/post';

export const posts: Post[] = [
    {
        title: 'La optimización de procesos logísticos en la gestión de una flota vehicular',
        image: 'assets/images/posts/post-1.png',
        categories: ['Special Offers'],
        date: 'Junio 02, 2020'
    },
    {
        title: 'Como ahorrar el 50% del gasto en gasolina usando gas natural',
        image: 'assets/images/posts/post-2.png',
        categories: ['Latest News'],
        date: 'Mayo 30, 2020'
    },
    {
        title: 'Importancia de un gasoducto en México',
        image: 'assets/images/posts/post-3.png',
        categories: ['New Arrivals'],
        date: 'Mayo 15, 2020'
    },
    {
        title: 'Conoce que estados en México consumen más Gas Natural',
        image: 'assets/images/posts/post-5.png',
        categories: ['Special Offers'],
        date: 'Abril 23, 2020'
    }
];
