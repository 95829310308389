import { Component, HostListener, Inject, NgZone, OnInit, PLATFORM_ID } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CartService } from './shared/services/cart.service';
import { CompareService } from './shared/services/compare.service';
import { WishlistService } from './shared/services/wishlist.service';
import { NavigationEnd, Router, RoutesRecognized } from '@angular/router';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { CurrencyService } from './shared/services/currency.service';
import { filter, first, pairwise } from 'rxjs/operators';
import { SharedService } from './shared/services/shared.service';
import { CompanyService } from './shared/services/company.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

    public t;

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private router: Router,
        private toastr: ToastrService,
        private sharedService: SharedService,
        private cart: CartService,
        private _companyService: CompanyService,
        private compare: CompareService,
        private wishlist: WishlistService,
        private zone: NgZone,
        private scroller: ViewportScroller,
        private currency: CurrencyService
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.zone.runOutsideAngular(() => {
                this.router.events.pipe(filter(event => event instanceof NavigationEnd), first()).subscribe(() => {
                    const preloader = document.querySelector('.site-preloader');

                    preloader.addEventListener('transitionend', (event: TransitionEvent) => {
                        if (event.propertyName === 'opacity') {
                            preloader.remove();
                        }
                    });
                    preloader.classList.add('site-preloader__fade');
                });
            });
        }
        this.router.events
            .pipe(filter(e => e instanceof RoutesRecognized))
            .pipe(pairwise())
            .subscribe((event: any[]) => {
              this.sharedService.beforeUrl = event[0].urlAfterRedirects;
        });
    }

    @HostListener("window:unload", [ "$event" ])
    unloadHandler(event) {
    }

    @HostListener("window:onload", [ "$event" ])
    onloadHandler(event) {
    }

    @HostListener('document:keypress', ['$event']) 
    keyPress(e) {
        if(this.router.url !== '/login'){
            this.resetTimer();
        }
    }

    @HostListener('document:mousedown', ['$event']) 
    onMouseDown(e) {
        if(this.router.url !== '/login'){
            this.resetTimer();
        }
    }

    resetTimer(){
        clearTimeout(this.t);
        this.t = setTimeout(() => {
            this._companyService.logout();
        }, 3600000);
    }

    @HostListener("window:beforeunload", [ "$event" ])
    beforeUnloadHander(event) {

    }

    ngOnInit(): void {
        // properties of the CurrencyFormatOptions interface fully complies
        // with the arguments of the built-in pipe "currency"
        // https://angular.io/api/common/CurrencyPipe
        this.currency.options = {
            code: 'USD',
            // display: 'symbol',
            // digitsInfo: '1.2-2',
            // locale: 'en-US'
        };

        this.router.events.subscribe((event) => {
            if ((event instanceof NavigationEnd)) {
                this.scroller.scrollToPosition([0, 0]);
            }
        });
        this.cart.onAdding$.subscribe(product => {
            this.toastr.success(`Producto "${product.name || product.title}" agregado al carro!`);
        });
        this.compare.onAdding$.subscribe(product => {
            this.toastr.success(`Producto "${product.name}" agregado to compare!`);
        });
        this.wishlist.onAdding$.subscribe(product => {
            this.toastr.success(`Producto "${product.name}" agregado a la lista de favoritos!`);
        });
    }
}
