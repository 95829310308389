import {Component, Input, OnInit} from '@angular/core';
import {StoreService} from '../../shared/services/store.service';
import {CartService} from '../../shared/services/cart.service';
import {ShopService} from 'src/app/shared/api/shop.service';
import {CompanyService} from '../../shared/services/company.service';
import {DirectionService} from '../../shared/services/direction.service';
import {SliderService} from './services/slider.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
    @Input() layout: 'classic' | 'compact' = 'classic';
    categories: any;

    sliders: any;

    options = {
        nav: false,
        autoplay: true,
        dots: true,
        loop: true,
        responsiveClass: true,
        responsive: {
            0: {items: 1}
        },
        rtl: this.direction.isRTL()
    };

    constructor(public store: StoreService,
                public _companyService: CompanyService,
                public cart: CartService,
                private _shopService: ShopService,
                private direction: DirectionService,
                private _sliderService: SliderService,
                private _router: Router) {
        this.getCategoryData();
        this.getSliders();
    }

    async getCategoryData() {
        this.categories = await this._shopService.getCategories();
    }

    async getSliders() {
        this.sliders = await this._sliderService.getSlidersBySucursal();
    }

    hasRoute(route: string) {
        return this._router.url.endsWith(route);
    }
}
