<!-- .dropcart -->
<div class="dropcart">
    <div class="dropcart__empty" *ngIf="!(cart.items$|async).length">
        Su carro esta vacio!
    </div>
    <ng-container *ngIf="(cart.items$|async).length">
        <div class="dropcart__buttons">
            <div class="row">
                <div class="col-12 pr-0 font-weight-bold text-center">
                    Tienes {{(cart.items$|async).length}} artículo{{(cart.items$|async).length != 1 ? 's' : ''}} en tu carrito.
                </div>
            </div>
        </div>
        <div class="dropcart__products-list">
            <div *ngFor="let item of cart.items$|async" class="dropcart__product">
                <div class="dropcart__product-image product-image">
                    <a *ngIf="item.product.coverImage" class="product-image__body" [routerLink]="root.product(item.product)" (click)="closeMenu.emit()">
                        <img *ngIf="item.product.coverImage !== 'no-photo.png'" class="product-image__img" [src]="URL_SERVICES_IMG + '/' + item.product.id + '/' +item.product.coverImage" alt="">
                        <img *ngIf="item.product.coverImage !== 'no-photo.png'" id="logo" class="logo" src="assets/images/logos/logo_footer.png" alt="logo">
                        <img *ngIf="item.product.coverImage === 'no-photo.png'" class="product-image__img" [src]="URL_SERVICES_IMG + '/' + '/' +item.product.coverImage" alt="">
                    </a>
                </div>
                <div class="dropcart__product-info">
                    <div class="dropcart__product-name">
                        <a [routerLink]="root.product(item.product)" (click)="closeMenu.emit()">{{ item.product.name }}</a>
                    </div>

                    <!-- <ul *ngIf="item.options.length" class="dropcart__product-options">
                        <li *ngFor="let option of item.options">{{ option.name }}: {{ option.value }}</li>
                    </ul> -->
                    <div class="dropcart__product-meta">
                        <span class="dropcart__product-quantity">{{ item.quantity }}</span> ×
                        <span class="dropcart__product-price">{{ item.product.current_price|currencyFormat }} MXN</span>
                    </div>
                </div>

                <button type="button" class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" (click)="remove(item)" [ngClass]="{'btn-loading': removedItems.includes(item)}">
                    <app-icon name="cross-10" size="10"></app-icon>
                </button>
            </div>
        </div>

        <div class="dropcart__totals">
            <table>
                <tr *ngIf="(cart.totals$|async).length">
                    <th>Subtotal</th>
                    <td>{{ cart.subtotal$|async|currencyFormat }} MXN</td>
                </tr>
                <tr *ngFor="let total of cart.totals$|async">
                    <th>{{ total.title }}</th>
                    <td>{{ total.price|currencyFormat }}</td>
                </tr>
                <tr>
                    <th>Total</th>
                    <td>{{ cart.total$|async|currencyFormat }} MXN</td>
                </tr>
            </table>
        </div>

        <!-- <div class="dropcart__buttons">
            <a class="btn btn-secondary" [routerLink]="root.cart()" (click)="closeMenu.emit()">Ver Carro</a> -->
        <!-- <a class="btn btn-primary" [routerLink]="root.checkout()" (click)="closeMenu.emit()">Checkout</a> -->
        <!-- </div> -->
        <div class="dropcart__buttons">
            <div class="row">
                <div class="col-12 pr-0">
                    <a class="btn btn-success btn-block mr-0 text-white" [routerLink]="root.cart()" (click)="closeMenu.emit()">Ver Carro</a>
                </div>
                <div class="col-12 mt-2 pr-0">
                    <a class="btn btn-cart btn-block text-white mr-0" (click)="emptyCar()">Vaciar Carro</a>
                </div>
            </div>
        </div>
    </ng-container>
</div>
<!-- .dropcart / end -->
