<div class="site-footer__widget footer-links">
    <h5 class="footer-links__title">{{ header }}</h5>
    <ul class="footer-links__list">
        <li *ngFor="let link of links" class="footer-links__item">
            <a *ngIf="!link.external" [routerLink]="link.url" class="footer-links__link">{{ link.label }}
                
            </a>
            <a *ngIf="link.external" href="{{ link.url }}" target="_blank" class="footer-links__link">{{ link.label }}
                
            </a>
        </li>
    </ul>
</div>