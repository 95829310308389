<div class="product-card" [ngClass]="{
    'product-card--layout--grid product-card--size--sm': layout === 'grid-sm',
    'product-card--layout--grid product-card--size--nl': layout === 'grid-nl',
    'product-card--layout--grid product-card--size--lg': layout === 'grid-lg',
    'product-card--layout--list':                        layout === 'list',
    'product-card--layout--horizontal':                  layout === 'horizontal'
}">
    <button class="product-card__quickview" type="button" appClick (click)="showQuickview()" [ngClass]="{'product-card__quickview--preload': showingQuickview}">
        <app-icon name="quickview-16" size="16"></app-icon>
        <span class="fake-svg-icon"></span>
    </button>

    <!-- <div *ngIf="product.badges.length" class="product-card__badges-list">
        <div *ngIf="product.badges.includes('sale')" class="product-card__badge product-card__badge--sale">Sale</div>
        <div *ngIf="product.badges.includes('hot')" class="product-card__badge product-card__badge--hot">Hot</div>
        <div *ngIf="product.badges.includes('new')" class="product-card__badge product-card__badge--new">New</div>
    </div> -->

    <div class="product-card__image product-image">
        <span class="btn-danger" *ngIf="product.days <= 60" style="color: white; padding-left: 5px; padding-right: 5px;">Nuevo</span>
        <a appClick [routerLink]="root.product(product)" *ngIf="product.coverImage" class="product-image__body">
            <img *ngIf="product.coverImage !== 'no-photo.png'" id="image" class="product-image__img" width="40" height="20" [src]="URL_SERVICES_IMG + '/' + product.id + '/' + product.coverImage"  alt="">
            <img *ngIf="product.coverImage === 'no-photo.png'" class="product-image__img"  [src]="URL_SERVICES_IMG + '/' + product.coverImage" alt="">
            <!-- <img class="logo" src="assets/images/logos/logo_footer.png" alt="logo"> -->

        </a>
    </div>
    <div class="product-card__info">
        <div class="product-card__name">
            <a appClick [routerLink]="['/producto', product.slug]">{{ product.name }}</a>
        </div>
        <!-- <div class="product-card__rating">
            <app-rating [value]="product.rating"></app-rating>
            <div class="product-card__rating-legend">{{ product.reviews }} Comentarios</div>
        </div> -->
        <ul *ngIf="featuredAttributes.length" class="product-card__features-list">
            <li *ngFor="let attribute of featuredAttributes">
                {{ attribute.name }}:
                <ng-container *ngFor="let value of attribute.values; let last = last">
                    {{ value.name }}
                    <ng-container *ngIf="!last">, </ng-container>
                </ng-container>
            </li>
        </ul>
    </div>
    <div class="product-card__actions">
        <!-- <div class="product-card__availability">
            Disponibilidad:
            <span *ngIf="product.stock >= 1" class="text-success">{{ product.stock }} Piezas</span>
        </div> -->
        <div class="product-card__prices">
            <span *ngIf="user.role.toLowerCase() !== 'vendedor'" [ngClass]="getPriceClass()">
                {{ getDisplayPrice().price | currencyFormat }} MXN
                <span *ngIf="getDisplayPrice().minQuantity" style="font-size: 12px;">
                    (Mínimo {{ getDisplayPrice().minQuantity }} pzas)
                </span>
            </span>
            <ng-container>
                <div class="row" style="font-size: 12px; color: #0f0f16; font-weight: normal; padding-top: 10px;">
                    <div class="col-7" style="padding-left: 5px; padding-right: 0;">
                        <img src="assets/images/icons/package.png" style="height: 20px; width: 20px; display: unset !important;"> Colectivo: {{product?.corrugado}} pzas
                    </div>
                    <div class="col-5" style="padding-right: 0;">
                        <span *ngIf="product?.stock > 0" class="text-success">{{product?.stock}} pzas Disponibles</span>
                        <span *ngIf="product?.stock === 0" class="text-danger">{{product?.stock}} pzas Disponibles</span>
                        <!-- <span *ngIf="product.stock > 0">
                            <img src="assets/images/icons/check.png" style="height: 20px; width: 20px; display: unset !important;">
                        </span>
                        <span *ngIf="product.stock <= 0">
                            <img src="assets/images/icons/cross.png" style="height: 20px; width: 20px; display: unset !important;">
                        </span> -->
                    </div>
                </div>

                <form *ngIf="user.role.toLowerCase() !== 'vendedor'" class="product__options">
                    <div class="row" style="padding-top: 10px;">
                        <div class="col-6">
                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [max]="product?.stock" [formControl]="quantity"></app-input-number>
                        </div>
                        <div class="col-6">
                            <button type="button" [disabled]="product.stock === 0" class="btn btn-danger btn-lg" [ngClass]="{'btn-loading': addingToCart}" appClick (click)="addToCart()">Agregar</button>
                        </div>
                    </div>
                </form>
            </ng-container>
        </div>
<!--        <div class="product-card__buttons">-->
<!--            <button class="btn btn-primary product-card__addtocart" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">Agregar al carro</button>-->
<!--            <button class="btn btn-secondary product-card__addtocart product-card__addtocart&#45;&#45;list" type="button" appClick (click)="addToCart()" [ngClass]="{'btn-loading': addingToCart}">Agregar al carro</button>-->
<!--            <button class="btn btn-light btn-svg-icon btn-svg-icon&#45;&#45;fake-svg product-card__wishlist" type="button" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">-->
<!--                <app-icon name="wishlist-16" size="16"></app-icon>-->
<!--                <span class="fake-svg-icon fake-svg-icon&#45;&#45;wishlist-16"></span>-->
<!--            </button>-->
            <!-- <button class="btn btn-light btn-svg-icon btn-svg-icon--fake-svg product-card__compare" type="button" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">
                <app-icon name="compare-16" size="16"></app-icon>
                <span class="fake-svg-icon fake-svg-icon--compare-16"></span>
            </button> -->
<!--        </div>-->
    </div>
</div>
