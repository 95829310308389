import { Pipe, PipeTransform } from '@angular/core';
import { URL_SERVICES_IMAGES } from '../config/config';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  transform( img: string, tipo: string = 'user', id): any {
    // console.log('img: ',img);
    // console.log('tipo: ',tipo);
    // console.log('id: ',id);
    console.log(img);

    let url = URL_SERVICES_IMAGES + 'public/img/';
    if ( img === 'no-photo.png' ) {
      return url + 'products/no-photo.png';
    }

    if ( img.indexOf('https') >= 0 ) {
      return img;
    }

    switch (tipo) {

      case 'user':
        url += 'user/' + id + '/' + img;
      break;

      case 'products':
        url += 'products/' + id + '/' + img;
      break;

      case 'training':
        url += 'trainings/' + id + '/' + img;
      break;

      case 'config':
        url += 'config/' + img;
      break;

      default:
        url += 'no-photo.png';
    }

    // console.log(url);

    return url;
  }

}
