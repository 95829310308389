import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-block-popup',
    templateUrl: './block-popup.component.html',
    styleUrls: ['./block-popup.component.scss']
})
export class BlockPopupComponent implements OnInit {

    @Input() srcImagen: any;

    constructor(public _activeModal: NgbActiveModal) {
    }

    ngOnInit(): void {
    }

    onClose(): void {
        this._activeModal.close();
    }
}
