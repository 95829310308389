import { Component } from '@angular/core';

@Component({
    selector: 'app-widget-tags',
    templateUrl: './widget-tags.component.html',
    styleUrls: ['./widget-tags.component.scss']
})
export class WidgetTagsComponent {
    constructor() { }
}
