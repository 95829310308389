<div class="site-footer__widget footer-contacts">
    <div class="text-center">
        <img class="text-center" src="assets/images/logos/logo_footer.png" alt="" width="150px">
    </div>
    <div class="footer-contacts__text pr-0">
        <p class="text-justify">Empresa 100% Mexicana dedicada al ramo farmaceutico mayorista y minorista. Con casi 1 década de experiencia en el mercado nacional.</p>
    </div>

    <!-- <ul class="footer-contacts__contacts">
        <li><i class="footer-contacts__icon fas fa-globe-americas"></i> {{ store.address }}</li>
        <li><i class="footer-contacts__icon far fa-envelope"></i> {{ store.email }}</li>
        <li><i class="footer-contacts__icon fas fa-mobile-alt"></i> {{ store.phone }}</li>
        <li><i class="footer-contacts__icon far fa-clock"></i> {{ store.hours }}</li>
    </ul> -->
</div>