import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {CartService} from '../../services/cart.service';
import {Product, ProductAttribute} from '../../interfaces/product';
import {WishlistService} from '../../services/wishlist.service';
import {CompareService} from '../../services/compare.service';
import {QuickviewService} from '../../services/quickview.service';
import {RootService} from '../../services/root.service';
import {CurrencyService} from '../../services/currency.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {URL_SERVICES_IMG} from '../../../config/config';
import {ToastrService} from 'ngx-toastr';
import {FormControl} from '@angular/forms';

@Component({
    selector: 'app-product-card',
    templateUrl: './product-card.component.html',
    styleUrls: ['./product-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductCardComponent implements OnInit, OnDestroy, OnChanges {
    private destroy$: Subject<void> = new Subject();

    @Input() product: any;
    @Input() layout: 'grid-sm' | 'grid-nl' | 'grid-lg' | 'list' | 'horizontal' | null = null;
    user: any;
    addingToCart = false;
    addingToWishlist = false;
    addingToCompare = false;
    showingQuickview = false;
    featuredAttributes: ProductAttribute[] = [];
    URL_SERVICES_IMG = URL_SERVICES_IMG;

    quantity: FormControl = new FormControl(0);
    @Input() priceList: string | null = null;
    constructor(
        private cd: ChangeDetectorRef,
        public root: RootService,
        public cart: CartService,
        public wishlist: WishlistService,
        public compare: CompareService,
        public quickview: QuickviewService,
        public currency: CurrencyService,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
        this.currency.changes$.pipe(takeUntil(this.destroy$)).subscribe(() => {
            this.cd.markForCheck();
        });
        this.user = JSON.parse( localStorage.getItem('user') );
        console.log('Producto only:',this.product);
    }

    getDisplayPrice(): { price: number, minQuantity: number | null } {

        if (this.user.price_list === 'oro') {
            return { price: this.product.price_gold, minQuantity: null };
        }
        if (this.user.price_list === 'plata') {
            return { price: this.product.price_silver, minQuantity: null };
        }

        return this.getBestPrice();
    }

    private getBestPrice(): { price: number, minQuantity: number | null } {
        const quantityValue = this.quantity.value;
        const stock = this.product.stock;

        if (stock === 0) {
            return { price: this.product.price, minQuantity: null };
        }

        const prices = [
            { price: this.product.price_4, quantity: this.product.mayoreo_4 },
            { price: this.product.price_3, quantity: this.product.mayoreo_3 },
            { price: this.product.price_2, quantity: this.product.mayoreo_2 },
            { price: this.product.price, quantity: 1 }
        ];

        for (const priceInfo of prices) {
            if (priceInfo.price && priceInfo.quantity && priceInfo.quantity <= stock) {
                if (quantityValue >= priceInfo.quantity || quantityValue === 0) {
                    return { price: priceInfo.price, minQuantity: priceInfo.quantity };
                }
            }
        }

        return { price: this.product.price, minQuantity: null };
    }

    getPriceClass(): string {
        if (this.user.price_list === 'oro') return 'text-gold';
        if (this.user.price_list === 'plata') return 'text-silver';
        const { price } = this.getDisplayPrice();
        if (price === this.product.price_4) return 'text-danger';
        if (price === this.product.price_3) return 'text-warning';
        if (price === this.product.price_2) return 'text-success';
        return '';
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // if ('product' in changes) {
        //     this.featuredAttributes = !this.product ? [] : this.product.attributes.filter(x => x.featured);
        // }
    }

    addToCartOld(): void {
        if (this.product.stock < this.quantity.value) {
            this.toastr.error('No disponible - No contamos con existencia');
            return;
        }

        if (this.addingToCart) {
            return;
        }

        if (!this.addingToCart && this.product && this.quantity.value > 0) {
            this.addingToCart = true;

            if (this.product.mayoreo_2 > 0 &&
                this.quantity.value >= this.product.mayoreo_2 &&
                this.quantity.value < this.product.mayoreo_3 &&
                this.product.mayoreo_3 > 0 && this.quantity.value < this.product.mayoreo_3) {
                this.product.current_price = this.product.price_2;

            } else if (this.product.mayoreo_2 > 0 &&
                this.quantity.value >= this.product.mayoreo_2 &&
                this.quantity.value < this.product.mayoreo_3 &&
                this.product.mayoreo_3 == 0) {
                this.product.current_price = this.product.price_2;

            } else if (this.product.mayoreo_3 > 0 &&
                this.quantity.value >= this.product.mayoreo_3 &&
                this.product.mayoreo_4 > 0 && this.quantity.value < this.product.mayoreo_4) {
                this.product.current_price = this.product.price_3;

            } else if (this.product.mayoreo_3 > 0 &&
                this.quantity.value >= this.product.mayoreo_3 &&
                this.product.mayoreo_4 == 0) {
                this.product.current_price = this.product.price_3;

            } else if (this.product.mayoreo_4 > 0 &&
                this.quantity.value >= this.product.mayoreo_4) {
                this.product.current_price = this.product.price_4;
            } else {
                this.product.current_price = this.product.price;
            }

            this.cart.add(this.product, this.quantity.value).subscribe({
                complete: () => {
                    this.addingToCart = false;
                    this.cd.markForCheck();
                }
            });
        }
    }
    addToCart(): void {
        if (this.product.stock < this.quantity.value) {
            this.toastr.error('No disponible - No contamos con existencia');
            return;
        }

        if (this.addingToCart) {
            return;
        }

        if (!this.addingToCart && this.product && this.quantity.value > 0) {
            this.addingToCart = true;

            const { price } = this.getDisplayPrice();
            this.product.current_price = price;

            this.cart.add(this.product, this.quantity.value).subscribe({
                complete: () => {
                    this.addingToCart = false;
                    this.cd.markForCheck();
                }
            });
        }
    }

    addToWishlist(): void {
        if (this.addingToWishlist) {
            return;
        }

        this.addingToWishlist = true;
        this.wishlist.add(this.product).subscribe({
            complete: () => {
                this.addingToWishlist = false;
                this.cd.markForCheck();
            }
        });
    }

    addToCompare(): void {
        if (this.addingToCompare) {
            return;
        }

        this.addingToCompare = true;
        this.compare.add(this.product).subscribe({
            complete: () => {
                this.addingToCompare = false;
                this.cd.markForCheck();
            }
        });
    }

    showQuickview(): void {
        if (this.showingQuickview) {
            return;
        }

        this.showingQuickview = true;
        this.quickview.show(this.product).subscribe({
            complete: () => {
                this.showingQuickview = false;
                this.cd.markForCheck();
            }
        });
    }
}
