<div class="site-header">
    <app-header-topbar></app-header-topbar>

    <div *ngIf="layout === 'classic'" class="site-header__middle container">
        <div class="site-header__logo">
            <a routerLink="/productos">
                <img src="assets/images/logos/logo_header.png" alt="">
            </a>
        </div>
        <div class="site-header__search">
            <app-search location="header"></app-search>
        </div>

        <!-- <app-icon class="ml-3 color-blue font-weight-bold" name="person-20" size="20"></app-icon> -->
        <div class="site-header__phone ml-2 mr-3 font-weight-bold">
            <div *ngIf="_companyService.isLogin()">
                <a class="account-menu__user" routerLink="/cuenta/dashboard">

                    <div class="account-menu__user-info" *ngIf="_companyService.isLogin()">
                        <!-- <div routerLink="/empresa/dashboard" class="account-menu__user-name" *ngIf="_companyService.isLoginCompany()">{{ _companyService.user.profile.name_company }}</div> -->
                        <div routerLink="/cuenta/dashboard" class="account-menu__user-name" *ngIf="_companyService.isLoginClient()">{{ _companyService.user.profile.name }} {{ _companyService.user.profile.lastname }}</div>
                        <!-- <div class="account-menu__user-email" *ngIf="_companyService.isLogin()">{{ _companyService.user.email }}</div> -->
                    </div>
                    <div class="account-menu__user-avatar ml-2">
                        <img src="./assets/images/avatars/avatar-3.jpg" *ngIf="!_companyService.user.image">
                        <img width="40px" height="40px" style="max-width:40px; max-height: 40px;" [src]="_companyService.user.image | image:'user': _companyService.user.id" *ngIf="_companyService.user.image">
                    </div>
                </a>
                <!-- <div class="account-menu__user-avatar">
                    <img src="./assets/images/avatars/avatar-3.jpg" *ngIf="!_companyService.user.image">
                    <img width="50px" height="40px" style="max-width:40px; max-height: 40px;" [src]="_companyService.user.image | image:'user': _companyService.user.id" *ngIf="_companyService.user.image">
                </div> -->
            </div>
            <!-- <div class="site-header__phone-title" *ngIf="!_companyService.isLogin()"><a routerLink="/cuenta/login"> Login </a>| <a routerLink="/cuenta/login">Registrarse</a></div>
            <div class="site-header__phone-title" *ngIf="_companyService.isLoginCompany()">{{ _companyService.user.profile.name_company }}</div>
            <div class="site-header__phone-title" *ngIf="_companyService.isLoginClient()">{{ _companyService.user.profile.name }} {{ _companyService.user.profile.lastname }}</div> -->
            <!-- <div class="site-header__phone-number">{{ store.primaryPhone }}</div> -->
        </div>
        <!-- <app-indicator icon="cart-20" [counter]="cart.quantity$|async">
        </app-indicator> -->
    </div>

    <div class="site-header__nav-panel">
        <app-header-nav [departments]="layout === 'classic'" [logo]="layout === 'compact'" [search]="layout === 'compact'" stickyMode="pullToShow"></app-header-nav>
    </div>

    <ng-container *ngIf="hasRoute('productos')">
        <div style="position: relative; z-index: -1 !important;">
            <owl-carousel-o *ngIf="sliders?.length" [options]="options" appOwlPreventClick>
                <ng-container *ngFor="let slide of sliders">
                    <ng-template class="slide" carouselSlide>
                        <img [src]="slide.imagen" [alt]="slide.nombre" [title]="slide.nombre">
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </ng-container>
</div>
