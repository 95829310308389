<div class="site-footer mt-0">
    <div class="container">
        <div class="site-footer__widgets">
            <div class="row">
                <div class="col-12 col-md-4 col-lg-4 mr-0 mr-md-5">
                    <app-footer-contacts></app-footer-contacts>
                    <app-social-links class="footer-newsletter__social-links" shape="circle"></app-social-links>
                </div>
                <div class="col-6 col-md-3 col-lg-3 mx-0 mx-md-5">
                    <app-footer-links header="COMPRA EN LÍNEA" [links]="[
                        {label: 'Preguntas Frecuentes', url: 'https://www.jeshuamedic.com/preguntas-frecuentes', external: true, target: '_blank'},
                        {label: 'Aviso de privacidad', url: 'https://www.jeshuamedic.com/aviso-de-privacidad', external: true, target: '_blank'},
                        {label: 'Políticas de envio', url: 'https://www.jeshuamedic.com/politicas-de-envio', external: true, target: '_blank'},
                        {label: 'Devoluciones y cancelaciones', url: 'https://www.jeshuamedic.com/devoluciones-y-cencelaciones', external: true, target: '_blank'},
                        {label: 'Términos y Condiciones', url: 'https://www.jeshuamedic.com/terminos-y-condiciones', external: true, target: '_blank'},
                        {label: 'Sucursales', url: 'https://www.jeshuamedic.com/contacto', external: true, target: '_blank'}
                    ]"></app-footer-links>
                </div>
                <div class="col-6 col-md-3 col-lg-3 ml-0 ml-md-4">
                    <app-footer-links header="CONTACTO"></app-footer-links>
                    <p>Dirección Zitácuro Michoacán</p>
                    <p>Email: info@jeshuamedic.com</p>
                    <p>Teléfono: (55) 723 238 232</p>
                    <br>
                    <h5>DESCARGA NUESTRA APP</h5>
                    <img width="120" src="assets/images/app-store.png" alt="">
                    <img width="120" src="assets/images/google-play.png" alt="">
                </div>
                <!-- <div class="col-12 col-md-12 col-lg-4">
                    <app-footer-newsletter class="site-footer__widget"></app-footer-newsletter>
                </div> -->
            </div>
        </div>
        <div class="site-footer__bottom">
            <div class="site-footer__copyright">
                © Todos los derechos reservados por JESHUAMEDIC S DE R L | <a href="http://www.dosbytes.com.mx" title="Diseño web" target="_blank">Diseño web</a> por dosbytes.com.mx </div>
            <!-- <div class="site-footer__payments">
                <img src="assets/images/payments.png" alt="">
            </div> -->
        </div>
    </div>
    <app-totop></app-totop>
</div>
