import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ShopService} from 'src/app/shared/api/shop.service';
import {HttpClient} from '@angular/common/http';
import {URL_SERVICES} from '../../../config/config';
import {CompanyService} from '../../../shared/services/company.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {SharedService} from '../../../shared/services/shared.service';
import { response } from 'express';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    URL_API = URL_SERVICES;
    URL_API_PROD = this.URL_API + 'products-api/'
    URL_API_PROD_SHOW = this.URL_API + 'products-api-show/'
    URL_API_TRAI = this.URL_API + 'trainings-api/'
    private productList: any;
    private productServiceList: any;
    private recentList: any;
    private popularList: any;
    public product;
    public popularProductsResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.popularList);
    public recentProductsResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.recentList);
    public productResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.productList);
    public productTermResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.productList);
    public productServiceResource: BehaviorSubject<any> = new BehaviorSubject<any>(this.productServiceList);
    public pagination: any = {
        meta: {},
        links: {}
    };

    public paginationServices: any = {
        meta: {},
        links: {}
    };

    constructor(
        private _shopService: ShopService,
        private _toastr: ToastrService,
        private _companyService: CompanyService,
        private http: HttpClient,
        private sharedService: SharedService,
        private router: Router
    ) {
        this.getProducts();
        this.getProductsTerm();
        this.getProductsService();
        this.getProductsListCapacitaciones();
        this.getRecentProduct();
        this.getPopularProduct();
    }

    public createProduct(product, images) {

        let url = URL_SERVICES + 'products-api';

        url += '?token=' + this._companyService.token;

        const data = product;

        data.images = JSON.stringify(images);
        data.user_id = this._companyService.user.id;

        return this.http.post(url, data)
            .subscribe((resp: any) => {
                this._toastr.success('Producto guardado correctamente.');
                this.router.navigate(['/empresa/productos']);
                return resp;
            }, (error: any) => {
                this._toastr.error(`Error creando el producto`);
            });
    }

    public createTraining(product, images) {

        let url = URL_SERVICES + 'trainings-api';

        url += '?token=' + this._companyService.token;

        const data = product;

        data.images = JSON.stringify(images);
        data.user_id = this._companyService.user.id;

        return this.http.post(url, data)
            .subscribe((resp: any) => {
                this._toastr.success('Capacitación guardada correctamente.');
                this.router.navigate(['/empresa/capacitaciones']);
                return resp;
            }, (error: any) => {
                this._toastr.error(`Error creando la capacitacion`);
            });
    }

    async getRecentProduct() {
        const resp: any = await this.getDataRecentProducts();

        if (resp) {
            this.recentList = resp.data.slice(-5);
            this.recentProductsResource.next(this.recentList);
        }
    }

    async getPopularProduct() {
        const resp: any = await this.getDataPopularProducts();

        if (resp) {
            this.popularList = resp.data.slice(-6);
            this.popularProductsResource.next(this.popularList);
        }
    }

    async getProducts(slug?, url?, sort?) {
        const resp: any = await this.getProductsList(slug ? slug : null, sort ? sort : 'a-z');
        if (resp.data) {
            this.productList = resp.data;
            this.pagination = {links: resp.links, meta: resp.meta}
        } else {
            this.productList = resp
        }
        this.productResource.next(this.productList);
    }

    async getProductsTerm(slug?, url?) {
        const resp: any = url ? await this.getProductFromUrl(url) : await this.getProductsList(slug ? slug : null);
        if (resp.data) {
            this.productList = resp.data;
            this.pagination = {links: resp.links, meta: resp.meta}
        } else {
            this.productList = resp
        }
        this.productTermResource.next(this.productList);
    }

    // getSearchTerm(term: string) {
    //     const sucursal = this._companyService.user.sucursal;
    //     let url = URL_API + 'get-products-search/' + term + '/' + 20000 + '/' + null + '/' + sucursal;
    //     return new Promise((resolve, reject) => {
    //         this.http.get(url)
    //         .toPromise()
    //         .then(resp => {
    //             console.log(resp);
    //             resolve(resp)
    //         })
    //         .catch(err => console.error(err)
    //         )
    //     })
    // }

    async getProductsService(slug?, url?) {
        const resp: any = url ? await this.getProductFromUrl(url) : await this.getProductsListServices(slug ? slug : null);
        if (resp.data) {
            this.productServiceList = resp.data;
            this.paginationServices = {links: resp.links, meta: resp.meta}
        } else {
            this.productServiceList = resp
        }
        this.productServiceResource.next(this.productServiceList);
    }

    getProductsList(categorySlug?: string | null, sort?: string): Promise<any> {
        const sucursal = this._companyService.user.sucursal;
        const pageActual = this.sharedService.pageActual;

        const user = this._companyService.user.id;
        // categorySlug ?
        const apiurl = `products-by-cat/${categorySlug}/${sucursal}/${user}/${sort} ?page=${pageActual}` ;
            // 'get-products-sucursal/' + sucursal + '/' + sort + '/' + user + `?page=${pageActual}`;
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })
    }

    getProductsListCapacitaciones(): Promise<any> {
        const apiurl = 'products-capacitaciones';
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })
    }

    getProductsListServices(categorySlug?: string | null): Promise<any> {
        const apiurl = categorySlug ? `products-services-by-category/${categorySlug}` : 'products-conversiones'
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}${apiurl}`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp);
                })
                .catch(this.handleError)
        })
    }

    getProductFromUrl(url): Promise<any> {
        return new Promise((resolve, reject) => {
            this.http.get(url)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp)
                })
                .catch(this.handleError)
        })
    }

    getProductDetail(slug: string) {
        const user = this._companyService.user.id;
        return this.http.get(`${this.URL_API_PROD_SHOW}${slug}/${user}`)
            .toPromise()
            // .then((resp: any) => {
            //     console.log(resp);
            //     resolve(resp.items);
            // })
            .catch(this.handleError)
        // })

    }

    getTrainingDetail(slug: string) {
        return this.http.get(`${this.URL_API_TRAI}${slug}`)
            .toPromise()
            // .then((resp: any) => {
            //     console.log(resp);
            //     resolve(resp.items);
            // })
            .catch(this.handleError)
        // })

    }

    getRelatedProducts(productId: any): Observable<any> {
        const user = this._companyService.user.id;
        return this.http.get(`${URL_SERVICES}products-related/${productId}/${this._companyService.user.sucursal}/${user}`)
        // return new Promise((resolve, rejects) => {
        //     this.http.get(`${this.URL_API_PROD}${categoryId}`)
        //             .toPromise()
        //             .then(resp => {
        //                 resolve(resp);
        //             })
        //             .catch(this.handleError);
        // })
    }

    getDataRecentProducts() {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}products-api`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp)
                })
                .catch(this.handleError)
        })
    }

    getDataPopularProducts() {
        return new Promise((resolve, reject) => {
            this.http.get(`${this.URL_API}products-api`)
                .toPromise()
                .then((resp: any) => {
                    resolve(resp)
                })
                .catch(this.handleError)
        })
    }

    // Upload images Product
    public postFileImagen(formData, token: string) {

        let url = URL_SERVICES + 'product-pictures';
        url += '?token=' + token;

        return this.http.post(url, formData);
    }

    // Upload images Product
    public postFileImagenTraining(formData, token: string) {

        let url = URL_SERVICES + 'training-pictures';
        url += '?token=' + token;

        return this.http.post(url, formData);
    }

    // Get Products By User
    public getProductsByUser() {

        const user = this._companyService.user.id;

        let url = URL_SERVICES + 'products-by-users/' + user;
        url += '?token=' + this._companyService.token;

        return this.http.get(url);

    }

    // Get Trainings By User
    public getTrainingsByUser() {

        const user = this._companyService.user.id;

        let url = URL_SERVICES + 'trainings-by-users/' + user;
        url += '?token=' + this._companyService.token;

        return this.http.get(url);

    }

    setFilterProducts(filters) {
        if (filters.range) {
            const newListProducts = this.productList.filter(product => product.price <= filters.range);
            this.productResource.next(newListProducts);
        }
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    // Activated or Desactivated Product
    public activatedProduct(activated: boolean, product: number) {

        let url = URL_SERVICES + 'activated-product/' + product;
        url += '?token=' + this._companyService.token;

        const data = {
            activated
        };
        return this.http.post(url, data);
    }

    public activatedTraining(activated: boolean, product: number) {

        let url = URL_SERVICES + 'activated-training/' + product;
        url += '?token=' + this._companyService.token;

        const data = {
            activated
        };
        return this.http.post(url, data);
    }

    // Delete image
    public deleteImage(image, product) {
        let token = this._companyService.token;

        let url = URL_SERVICES + 'delete-image/' + image + '/' + product;
        url += '?token=' + token;

        return this.http.post(url, '');
    }

    // Get Products By Id
    public getProduct(id: number) {
        const user = this._companyService.user.id;
        const url = URL_SERVICES + 'products-api-show/' + id + '/' + user;
        return this.http.get(url);

        // return this.products().pipe(map(items => { return items.find((item: Product) => { return item.id === id; }); }));
    }

    // Get Products By Id
    public getTraining(id: number) {

        const url = URL_SERVICES + 'trainings-api/' + id;
        return this.http.get(url);

        // return this.products().pipe(map(items => { return items.find((item: Product) => { return item.id === id; }); }));
    }

    /*
      ---------------------------------------------
      ----------  Update Product  ----------------
      ---------------------------------------------
    */

    public updateProduct(productId, product, images) {

        let url = URL_SERVICES + 'products-api/' + productId;
        url += '?token=' + this._companyService.token;

        const data = product;

        data.images = JSON.stringify(images);
        data.user_id = this._companyService.user.id;

        return this.http.put(url, data).subscribe((resp: any) => {
            this._toastr.success('Producto guardado correctamente.');
            this.router.navigate(['/empresa/productos']);
            return resp;
        }, (error: any) => {
            this._toastr.error(`Error creando el producto`);
        });
        ;

    }


    public updateTraining(productId, product, images) {

        let url = URL_SERVICES + 'trainings-api/' + productId;
        url += '?token=' + this._companyService.token;

        const data = product;

        data.images = JSON.stringify(images);
        data.user_id = this._companyService.user.id;

        return this.http.put(url, data).subscribe((resp: any) => {
            this._toastr.success('Capacitación actualizada correctamente.');
            this.router.navigate(['/empresa/capacitaciones']);
            return resp;
        }, (error: any) => {
            this._toastr.error(`Error creando la capacitación`);
        });
        ;

    }

    /*
        ---------------------------------------------
        ----------  Delete Product  ----------------
        ---------------------------------------------
     */

    deleteProduct(product) {

        const token = this._companyService.token;

        let url = URL_SERVICES + 'products-api/' + product;
        url += '?token=' + token;

        return this.http.delete(url);

    }

    /*
        ---------------------------------------------
        ----------  Delete Training  ----------------
        ---------------------------------------------
     */

    deleteTraining(product) {

        const token = this._companyService.token;

        let url = URL_SERVICES + 'trainings-api/' + product;
        url += '?token=' + token;

        return this.http.delete(url);

    }

    getProductsMasiva(products: any) {

        const user = this._companyService.user.id;
        const sucursal = this._companyService.user.sucursal;

        const data = {
            user,
            sucursal,
            products
        };

        const url = URL_SERVICES + 'get-products-by-sku';
        return this.http.post(url, data);
    }
}
