import { Component, Input } from '@angular/core';
import { Product } from '../../../shared/interfaces/product';
import { RootService } from '../../../shared/services/root.service';
import { URL_SERVICES_IMG } from '../../../config/config';

@Component({
    selector: 'app-widget-products',
    templateUrl: './widget-products.component.html',
    styleUrls: ['./widget-products.component.scss']
})
export class WidgetProductsComponent {
    @Input() header = '';
    @Input() products: Product[] = [];
    URL_SERVICES_IMG = URL_SERVICES_IMG;

    constructor(public root: RootService) { }
}
