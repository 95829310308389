<div class="widget-posts widget">
    <h4 class="widget__title">Latest Posts</h4>
    <div class="widget-posts__list">
        <div *ngFor="let post of posts" class="widget-posts__item">
            <div class="widget-posts__image">
                <a [routerLink]="root.post()">
                    <img [src]="postImage(post)" alt="">
                </a>
            </div>
            <div class="widget-posts__info">
                <div class="widget-posts__name">
                    <a [routerLink]="root.post()">{{ post.title }}</a>
                </div>
                <div class="widget-posts__date">{{ post.date }}</div>
            </div>
        </div>
    </div>
</div>
