<!-- begin:: Page -->

<div class="kt-grid kt-grid--ver kt-grid--root" style="height: 100%">
    <div class="kt-grid kt-grid--hor kt-grid--root  kt-login kt-login--v6 kt-login--signin" id="kt_login">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div class="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside">
                <div class="kt-login__wrapper">
                    <div class="kt-login__container">
                        <div class="kt-login__body">
                            <div class="kt-login__logo">
                                <a href="#">
                                    <img src="assets/images/logos/logo_header.png">
                                </a>
                            </div>
                            <div class="kt-login__signin">
                                <div class="kt-login__head">
                                    <h3 class="kt-login__title">Acceso a Clientes</h3>
                                </div>
                                <div class="kt-login__form">
                                    <form action="" [formGroup]="forma" (ngSubmit)="login()" class="kt-form" id="kt_login_form">
                                        <div class="form-group">
                                            <input class="form-control" type="email" placeholder="E-mail" formControlName="email" autocomplete="off">
                                        </div>
                                        <div class="input-group">
                                            <input class="form-control" [type]="inputType" placeholder="Contraseña" formControlName="password" autocomplete="off">
                                            <div class="input-group-prepend">
                                                <!-- <a class="pointer" > -->
                                                <span class="input-group-text" id="basic-addon1" *ngIf="visible">
                                                        <i (click)="viewPassword()" class="pointer fa fa-eye" aria-hidden="true"></i>
                                                    </span>
                                                <span class="input-group-text" id="basic-addon2" *ngIf="!visible">
                                                        <i (click)="viewPassword()" class="pointer fa fa-eye-slash" aria-hidden="true"></i>
                                                    </span>
                                                <!-- </a> -->
                                            </div>
                                        </div>

                                        <!--begin::Action-->
                                        <div class="kt-login__actions">

                                            <button id="" type="submit" class="btn btn-danger btn-block btn-elevate kt-login__btn-primary">Iniciar Sesión</button>
                                        </div>

                                        <!--end::Action-->
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="kt-login__account">
                        <span class="kt-login__account-msg">
                            © Todos los derechos reservados por JeshuaMedic S de R L | <a href="http://www.dosbytes.com.mx" title="Diseño web" target="_blank">Diseño web</a> por dosbytes.com.mx
                            </span>
                    </div> -->
                </div>
            </div>
            <div class="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content" style="background-image: url(assets/images/banners/fondo_login.png);">
                <div class="kt-login__section">
                    <div class="kt-login__block">
                        <h5 class="text-right text-white">Para ayuda escribenos en
                            <a href="https://api.whatsapp.com/send?phone=527152184593&text=Hola%20necesito%20informacion%20sobre%20tus%20productos...%F0%9F%98%80" target="_blank"><span class="text-right badge rounded-pill bg-light text-dark">
                            <img src="assets/images/icons/iconwhat.png" width="20" alt=""> Contáctanos</span></a></h5>
                        <div class="kt-login__desc text-center">
                            <img class="fit-image2" src="assets/images/banners/img_logiin.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="max-height-50 height-40 kt-grid__item kt-grid__item--fluid kt-grid kt-grid--desktop kt-grid--ver-desktop kt-grid--hor-tablet-and-mobile">
            <div class="kt-grid__item  kt-grid__item--order-tablet-and-mobile-2  kt-grid kt-grid--hor kt-login__aside p-0">
                <div class="justify-content-center kt-login__wrapper p-2">
                    <!-- <div class="kt-login__container"> -->
                    <div class="kt-login__body">
                        <span class="kt-login__account-msg p-2">
                                <small> © Todos los derechos reservados por JESHUMEDIC S DE R L | Diseño web por <a href="http://www.dosbytes.com.mx" title="Diseño web" target="_blank">dosbytes.com.mx</a></small>
                            </span>
                    </div>
                    <!-- </div> -->
                    <!-- <div class="kt-login__account">
                        <span class="kt-login__account-msg">
                            © Todos los derechos reservados por JeshuaMedic S de R L | <a href="http://www.dosbytes.com.mx" title="Diseño web" target="_blank">Diseño web</a> por dosbytes.com.mx
                            </span>
                    </div> -->
                </div>
            </div>
            <div class="kt-grid__item kt-grid__item--fluid kt-grid__item--center kt-grid kt-grid--ver kt-login__content p-0">
                <div class="kt-login__section justify-content-end">
                    <div class="kt-login__block">
                        <div class="kt-login__desc text-center pr-3">
                            <small class="text-dark">Siguenos en nuestras redes sociales</small>
                            <span><a class="mr-2" href="https://www.facebook.com/jeshuamedic/" target="_blank"><img src="assets/images/icons/icon_face.png" width="20" alt=""></a></span>
                            <span><a class="mr-2" href="#" target="_blank"><img src="assets/images/icons/icon_twit.png" width="18" alt=""></a></span>
                            <span><a class="mr-2" href="https://www.instagram.com/jeshuamedic/" target="_blank"><img src="assets/images/icons/icon_insta.png" width="20" alt=""></a></span>
                            <span><a href="#" target="_blank"><img src="assets/images/icons/icon_yout.png" width="20" alt=""></a></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>