<div class="product product--layout--{{ layout }}" *ngIf="product">
    <div class="product__content">
        <!-- .product__gallery -->
        <div class="product__gallery" *ngIf="product.pictures">
            <span class="btn-danger" *ngIf="product.days <= 60" style="color: white; padding-left: 5px; padding-right: 5px;">Nuevo</span>
            <app-product-gallery [productLayout]="layout" [idProduct]="product.id" [images]="product.pictures"></app-product-gallery>
        </div>
        <!-- .product__gallery / end -->
        <!-- .product__info -->
        <div class="product__info">
            <div class="product__wishlist-compare">
                <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Favoritos" appClick (click)="addToWishlist()" [ngClass]="{'btn-loading': addingToWishlist}">
                    <app-icon name="wishlist-16" size="16"></app-icon>
                </button>
                <!-- <button type="button" class="btn btn-sm btn-light btn-svg-icon" data-toggle="tooltip" data-placement="right" title="Comparar" appClick (click)="addToCompare()" [ngClass]="{'btn-loading': addingToCompare}">
                    <app-icon name="compare-16" size="16"></app-icon>
                </button> -->
            </div>

            <h1 class="product__name">{{ product.name }}</h1>
            <!-- <div class="product__rating mb-3">
                <div class="product__rating-stars">
                    <app-rating [value]="product.rating"></app-rating>
                </div>
                <div class="product__rating-legend">
                    <a href="" appClick>{{ product.reviews }} </a>
                </div>
            </div> -->
            <h5>Descripción</h5>
            <div class="product__description product__meta mt-0">
                <!-- Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur ornare, mi in ornare elementum, libero nibh lacinia urna, quis convallis lorem erat at purus. Maecenas eu varius nisi. -->
                {{product.description}}
            </div>
            <!-- <ul class="product__features">
                <li>Speed: 750 RPM</li>
                <li>Power Source: Cordless-Electric</li>
                <li>Battery Cell Type: Lithium</li>
                <li>Voltage: 20 Volts</li>
                <li>Battery Capacity: 2 Ah</li>
            </ul> -->
            <ul class="product__meta">
                <li class="product__meta-availability">
                    <span class="font-weight-bold text-height-16 text-blue">Disponibilidad: {{product.stock}} pzas </span>
                </li>
                <li><span class="font-weight-bold text-height-16">Código:</span> <span class="font-weight-bold text-height-16">{{ product.sku }}</span></li>
                <li><span class="font-weight-bold text-height-16">Colectivo: {{ product?.corrugado !== null ? product?.corrugado : '..' }} pzas</span></li>
            </ul>
            <ul class="product__meta">
                <li><span class="font-weight-bold">Laboratorio: </span> <a href="" appClick>{{product.department}}</a></li>
            </ul>
        </div>
        <!-- .product__info / end -->
        <!-- .product__sidebar -->
        <div class="product__sidebar">
            <div class="product__availability">
                Disponible: <span class="text-success">En Stock {{ product.stock }}</span>
            </div>

            <div *ngIf="user.role.toLowerCase() !== 'vendedor'" class="product__prices text-blue">
                <div class="row">
                    <div class="col-5" style="padding-top: 10px; padding-bottom: 5px;">
                        <span [ngClass]="getPriceClass()">
                            {{ getDisplayPrice().price | currencyFormat }} MXN
                            <span *ngIf="getDisplayPrice().minQuantity" style="font-size: 12px;">
                                (Mínimo {{ getDisplayPrice().minQuantity }} pzas)
                            </span>
                        </span><br/>
                        <span class="text-black" *ngIf="product.iva != 0"> Precio incluye IVA</span>
                    </div>
                    <div class="col-7" style="background-color: #0081d4; padding-top: 10px; padding-bottom: 5px;">
                        <form class="product__options">
                            <div class="form-group product__option">
                                <div class="product__actions">
                                    <div class="product__actions-item">
                                        <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [formControl]="quantity"></app-input-number>
                                    </div>
                                    <div class="product__actions-item product__actions-item--addtocart" style="margin-top: 0px !important;">
                                        <button type="button" class="btn btn-danger btn-lg" [ngClass]="{'btn-loading': addingToCart}" appClick (click)="addToCart()">Agregar</button>
                                    </div>
                                    <div class="product__actions-item product__actions-item--wishlist" style="margin-top: 0px !important;">
                                        <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Favoritos" [ngClass]="{'btn-loading': addingToWishlist}" appClick (click)="addToWishlist()">
                                            <app-icon name="wishlist-16" size="16"></app-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div *ngIf="user.price_list === null" class="product-tabs" style="margin-top: 0 !important;" [ngClass]="{'product-tabs--layout--sidebar': false}">
                <div class="product-tabs__list" *ngIf="user.role.toLowerCase() !== 'vendedor'">
                    <a href="#tab-description" class="product-tabs__item ml-0"
                       [ngClass]="{'product-tabs__item--active': tab === 'description'}"
                       (click)="$event.preventDefault(); tab = 'description'"
                       style="padding-left: 0px; color: #0f0f16; font-size: 18px !important;">
                        Tabla de precios por piezas
                    </a>
                </div>
                <div style="padding: 10px 5px 5px 5px !important; border-top-color: #f0f0f0; border-top-style: solid; border-top-width: 2px;">
                    <div class="product-tabs__pane" *ngIf="user.role.toLowerCase() !== 'vendedor'" id="tab-description" [ngClass]="{'product-tabs__pane--active': tab === 'description'}">
                        <table class="table table-responsive">
                            <thead style="font-size: 14px !important;">
                            <tr class="border table-primarys">
                                <th class="border px-5 text-center" *ngIf="product.mayoreo_2 > 0">Hasta {{ product.mayoreo_2 }} piezas</th>
                                <th class="border px-5 text-center" *ngIf="product.mayoreo_2 == 0">Hasta 5 piezas</th>
                                <th class="border px-5 text-center" *ngIf="product.mayoreo_3 > 0">Hasta {{ product.mayoreo_3 }} piezas</th>
                                <th class="border px-5 text-center" *ngIf="product.mayoreo_3 == 0">Hasta 10 piezas</th>
                                <th class="border px-5 text-center" *ngIf="product.mayoreo_4 > 0">Hasta {{ product.mayoreo_4 }} piezas</th>
                                <th class="border px-5 text-center" *ngIf="product.mayoreo_4 == 0">Hasta 15 piezas</th>
                            </tr>
                            </thead>
                            <tbody style="font-size: 15px !important;">
                            <tr class="border">
                                <td class="border px-5 text-center" *ngIf="product.mayoreo_2 > 0" scope="row">${{ product.price_2 }} MXN</td>
                                <td class="border px-5 text-center" *ngIf="product.mayoreo_2 == 0" scope="row"> -- </td>
                                <td class="border px-5 text-center" *ngIf="product.mayoreo_3 > 0">${{ product.price_3 }} MXN</td>
                                <td class="border px-5 text-center" *ngIf="product.mayoreo_3 == 0"> -- </td>
                                <td class="border px-5 text-center" *ngIf="product.mayoreo_4 > 0">${{ product.price_4 }} MXN</td>
                                <td class="border px-5 text-center" *ngIf="product.mayoreo_4 == 0"> -- </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- Tabla de sucursales por piezas -->
                <div class="product-tabs__list">
                    <a href="#tab-description" class="product-tabs__item ml-0"
                       [ngClass]="{'product-tabs__item--active': tab === 'description'}"
                       (click)="$event.preventDefault(); tab = 'description'"
                       style="padding-left: 0px; color: #0f0f16; font-size: 18px !important;">
                        Tabla de Piezas por sucurzales
                    </a>
                </div>
                <div style="padding: 10px 5px 5px 5px !important; border-top-color: #f0f0f0; border-top-style: solid; border-top-width: 2px;">
                    <div class="product-tabs__pane" id="tab-description" [ngClass]="{'product-tabs__pane--active': tab === 'description'}">
                        <table class="table table-responsive">
                            <thead style="font-size: 14px !important;">
                                <tr class="border table-primarys">
                                    <th class="border px-5 text-center">Sucursal</th>
                                    <th class="border px-5 text-center">Cantidad</th>
                                </tr>
                            </thead>
                            <tbody style="font-size: 15px !important;">
                                <tr class="border" *ngFor="let sucursal of filteredSucursals()">
                                    <td class="border px-5 text-center">{{ sucursal.name }}</td>
                                    <td class="border px-5 text-center">{{ sucursal.quantity }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- .product__options -->
<!--            <form class="product__options">-->
                <!-- <div class="form-group product__option">
                    <label class="product__option-label">Color</label>
                    <div class="input-radio-color">
                        <div class="input-radio-color__list">
                            <label class="input-radio-color__item input-radio-color__item--white" style="color: #fff;" data-toggle="tooltip" title="White">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ffd333;" data-toggle="tooltip" title="Yellow">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item" style="color: #ff4040;" data-toggle="tooltip" title="Red">
                                <input type="radio" name="color">
                                <span></span>
                            </label>
                            <label class="input-radio-color__item input-radio-color__item--disabled" style="color: #4080ff;" data-toggle="tooltip" title="Blue">
                                <input type="radio" name="color" disabled>
                                <span></span>
                            </label>
                        </div>
                    </div>
                </div> -->
                <!-- <div class="form-group product__option">
                    <label class="product__option-label">Marca</label>
                    <div class="input-radio-label">
                        <div class="input-radio-label__list">
                            <label>
                                <input type="radio" name="material">
                                <span>{{ product.brandName }}</span>
                            </label> -->
                <!-- <label>
                                <input type="radio" name="material">
                                <span>Wood</span>
                            </label>
                            <label>
                                <input type="radio" name="material" disabled>
                                <span>Plastic</span>
                            </label> -->
                <!-- </div>
                    </div>
                </div> -->
<!--                <div class="form-group product__option">-->
<!--                    <label class="product__option-label">Cantidad</label>-->
<!--                    <div class="product__actions">-->
<!--                        <div class="product__actions-item">-->
<!--                            <app-input-number aria-label="Quantity" class="product__quantity" size="lg" [min]="1" [formControl]="quantity"></app-input-number>-->
<!--                        </div>-->
<!--                        <div class="product__actions-item product__actions-item&#45;&#45;addtocart">-->
<!--                            <button type="button" class="btn btn-danger btn-lg" [ngClass]="{'btn-loading': addingToCart}" appClick (click)="addToCart()">Agregar al carro</button>-->
<!--                        </div>-->
<!--                        <div class="product__actions-item product__actions-item&#45;&#45;wishlist">-->
<!--                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Favoritos" [ngClass]="{'btn-loading': addingToWishlist}" appClick (click)="addToWishlist()">-->
<!--                                <app-icon name="wishlist-16" size="16"></app-icon>-->
<!--                            </button>-->
<!--                        </div>-->
<!--                        &lt;!&ndash; <div class="product__actions-item product__actions-item&#45;&#45;compare">-->
<!--                            <button type="button" class="btn btn-secondary btn-svg-icon btn-lg" data-toggle="tooltip" title="Comparar" [ngClass]="{'btn-loading': addingToCompare}" appClick (click)="addToCompare()">-->
<!--                                <app-icon name="compare-16" size="16"></app-icon>-->
<!--                            </button>-->
<!--                        </div> &ndash;&gt;-->
<!--                    </div>-->
<!--                </div>-->
                <!-- <div class="form-group product__option mb-0 border px-2">
                    <label class="product__option-label"><span class="text-success">Tiempo de entrega</span>: {{ product.days }} días</label>
                </div>
                <div class="form-group product__option mt-2 mb-0 border px-2">
                    <label class="product__option-label"><span class="text-success">Origen de Producto</span>: {{ product.origen }}</label>
                </div>
                <div class="form-group product__option mt-2 border px-2">
                    <label class="product__option-label" *ngIf="product.user"><span class="text-success">Distribuido por</span>: {{ product.user.profile.name_company }}</label>
                    <label class="product__option-label" *ngIf="!product.user"><span class="text-success">Distribuido por</span>: Eneralt</label>
                </div> -->
<!--            </form>-->
            <!-- .product__options / end -->
        </div>



        <!-- .product__end -->
        <div class="product__footer">
            <!-- <div class="product__tags tags">
                <div class="tags__list">
                    <a href="" appClick>Mounts</a>
                    <a href="" appClick>Electrodes</a>
                    <a href="" appClick>Chainsaws</a>
                </div>
            </div> -->

            <div class="product__share-links share-links">
                <ul class="share-links__list">
                    <!-- <li class="share-links__item share-links__item--type--like"><a href="https://www.facebook.com/sharer.php?u=https://eneralt.com.mx{{ url }}" target="_blank" appClick>Like</a></li>
                    <li class="share-links__item share-links__item--type--tweet"><a href="https://twitter.com/intent/tweet?text=https://eneralt.com.mx{{ url }}" target="_blank" appClick>Tweet</a></li> -->
                    <!-- <li class="share-links__item share-links__item--type--pin"><a href="" appClick>Pin It</a></li>
                    <li class="share-links__item share-links__item--type--counter"><a href="" appClick>4K</a></li> -->
                </ul>
            </div>
        </div>
    </div>
</div>
