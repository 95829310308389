import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    pageActual: number = 1;
    pageActualPromotions: number = 1;
    pageActualSearch: number = 1;
    beforeUrl: string;
    showCategory: boolean = false;
    constructor(
    ) { 
    }

}
