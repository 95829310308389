import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Product } from '../interfaces/product';
import { CartItem } from '../interfaces/cart-item';
import { BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';
import { CompanyService } from './company.service';
import { URL_SERVICES } from '../../config/config';
import { HttpClient } from '@angular/common/http';

interface CartTotal {
    title: string;
    price: number;
    type: 'shipping'|'fee'|'tax'|'other';
}

interface CartData {
    items: CartItem[];
    quantity: number;
    subtotal: number;
    totals: CartTotal[];
    total: number;
}

@Injectable({
    providedIn: 'root'
})
export class CartService {
    public data: CartData = {
        items: [],
        quantity: 0,
        subtotal: 0,
        totals: [],
        total: 0
    };

    private itemsSubject$: BehaviorSubject<CartItem[]> = new BehaviorSubject(this.data.items);
    private quantitySubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.quantity);
    private subtotalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.subtotal);
    private totalsSubject$: BehaviorSubject<CartTotal[]> = new BehaviorSubject(this.data.totals);
    private totalSubject$: BehaviorSubject<number> = new BehaviorSubject(this.data.total);
    private onAddingSubject$: Subject<Product> = new Subject();

    get items(): ReadonlyArray<CartItem> {
        return this.data.items;
    }

    get quantity(): number {
        return this.data.quantity;
    }

    get total(): number {
        return this.data.total;
    }

    readonly items$: Observable<CartItem[]> = this.itemsSubject$.asObservable();
    readonly quantity$: Observable<number> = this.quantitySubject$.asObservable();
    readonly subtotal$: Observable<number> = this.subtotalSubject$.asObservable();
    readonly totals$: Observable<CartTotal[]> = this.totalsSubject$.asObservable();
    readonly total$: Observable<number> = this.totalSubject$.asObservable();

    readonly onAdding$: Observable<Product> = this.onAddingSubject$.asObservable();

    constructor(
        @Inject(PLATFORM_ID)
        private platformId: any,
        public _companyService: CompanyService,
        private _http: HttpClient
    ) {
        // if (isPlatformBrowser(this.platformId)) {
            this.load();
        //     this.calc();
        // }
    }

    add(product: Product, quantity: number, options: {name: string; value: string}[] = []): Observable<CartItem> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            this.onAddingSubject$.next(product);

            let item = this.items.find(eachItem => {
                if (eachItem.product.id !== product.id || eachItem.options.length !== options.length) {
                    return false;
                }

                // if (eachItem.options.length) {
                //     for (const option of options) {
                //         if (!eachItem.options.find(itemOption => itemOption.name === option.name && itemOption.value === option.value)) {
                //             return false;
                //         }
                //     }
                // }

                return true;
            });

            if (item) {
                item.quantity += quantity;
            } else {
                item = {product, quantity, options};
                this.data.items.push(item);
            }

            this.save(product, quantity);
            this.calc();

            return item;
        }));
    }

    update(updates: {item: CartItem, quantity: number}[]): Observable<void> {
        // timer only for demo
        return timer(1000).pipe(map(() => {
            updates.forEach(update => {
                const item = this.items.find(eachItem => eachItem === update.item);

                if (item) {
                    item.quantity = update.quantity;
                }
            });
            this.save(updates[0].item.product, updates[0].item.quantity);
            this.calc();
        }));
    }

    remove(item: CartItem): Observable<void> {
        // timer only for demo
        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'deleteToCar';
        const data = {
            user_id: user,
            product_id: item.product.id
        }

        return this._http.post( url, data ).pipe(map(() => {
            this.data.items = this.data.items.filter(eachItem => eachItem !== item);

            this.save();
            this.calc();
            // this.onAddingSubject$.next(product);
            // this.data.items.push(product);
            // this.itemsSubject$.next(this.data.items);
        }));

        // return timer(1000).pipe(map(() => {
        //     this.data.items = this.data.items.filter(eachItem => eachItem !== item);

        //     this.save();
        //     this.calc();
        // }));
    }

    removeAll(): Observable<void> {
        // timer only for demo
        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'deleteToAllCar';
        const data = {
            user_id: user,
        }

        return this._http.post( url, data ).pipe(map(() => {
            this.data.items = [];

            this.save();
            this.calc();
        }));
    }

    public calc(): void {
        let quantity = 0;
        let subtotal = 0;
        let iva = 0;
        const user = JSON.parse( localStorage.getItem('user') );

        this.data.items.forEach(item => {
            if(user?.price_list){
                item.product.current_price = user?.price_list === 'plata' && item.product.price_silver;
                item.product.current_price = user?.price_list === 'oro' && item.product.price_gold;
            }else if( item.product.mayoreo_2 > 0 &&
                item.quantity >= item.product.mayoreo_2 &&
                item.quantity < item.product.mayoreo_3 &&
                item.product.mayoreo_3 > 0 && item.quantity < item.product.mayoreo_3) {
                    item.product.current_price = item.product.price_2;

            }else if (item.product.mayoreo_2 > 0 &&
                item.quantity >= item.product.mayoreo_2 &&
                item.quantity < item.product.mayoreo_3 &&
                item.product.mayoreo_3 == 0) {
                    item.product.current_price = item.product.price_2;

            } else if(item.product.mayoreo_3 > 0 &&
                item.quantity >= item.product.mayoreo_3 &&
                item.product.mayoreo_4 > 0 && item.quantity < item.product.mayoreo_4){
                    item.product.current_price = item.product.price_3;

            }else if(item.product.mayoreo_3 > 0 &&
                item.quantity >= item.product.mayoreo_3 &&
                item.product.mayoreo_4 === 0){
                    item.product.current_price = item.product.price_3;

            }else if(item.product.mayoreo_3 > 0 &&
                        item.quantity >= item.product.mayoreo_3 &&
                        item.product.mayoreo_4 == 0){
                            item.product.current_price = item.product.price_3;

            } else if(item.product.mayoreo_4 > 0 &&
                item.quantity >= item.product.mayoreo_4){
                    item.product.current_price = item.product.price_4;
            }else{
                item.product.current_price = item.product.price;
            }

            quantity += item.quantity;
            subtotal += item.product.current_price * item.quantity;
            iva += item.product.priceWithoutIva * item.quantity * item.product.iva;
        });

        const totals: CartTotal[] = [];

        // totals.push({
        //     title: 'Envio',
        //     price: 25,
        //     type: 'shipping'
        // });
        totals.push({
            title: 'IVA',
            price: iva,
            // price: subtotal * 0.16,
            type: 'tax'
        });

        // const total = subtotal + totals.reduce((acc, eachTotal) => acc + eachTotal.price, 0);
        const total = subtotal;

        this.data.quantity = quantity;
        this.data.subtotal = subtotal;
        this.data.totals = totals;
        this.data.total = total;

        this.itemsSubject$.next(this.data.items);
        this.quantitySubject$.next(this.data.quantity);
        this.subtotalSubject$.next(this.data.subtotal);
        this.totalsSubject$.next(this.data.totals);
        this.totalSubject$.next(this.data.total);
    }

    public removeAllCart() {

        this.data.items = [];

        const user = this._companyService.user.id;

        const url = URL_SERVICES + 'deleteToAllCar';
        const item = {
            user_id: user
        }

        this._http.post( url, item ).subscribe( (product) => {
            console.log(product);
            this.save();
            this.calc();
            // this.onAddingSubject$.next(product);
            // this.data.items.push(product);
            // this.itemsSubject$.next(this.data.items);
        });

            // this.save();
            // this.calc();

      }

    private save(product?, quantity?): void {

        const user = this._companyService.user.id;

        let url = URL_SERVICES + 'addToCar';
        url += '?token=' + this._companyService.token;

        if(product) {
            const item = {
                user_id: user,
                product_id: product.id,
                quantity
            }

            this._http.post( url, item ).subscribe( (product) => {
                console.log('Product on Cart:',product);
            });
        }
    }

    private load(): void {
        this.data.items = [];

        console.log('CpmpanyService:',this._companyService.user);
        if(this._companyService.user) {
            const user = this._companyService.user.id;

            const url = URL_SERVICES + 'getCar/' + user;

            this._http.get( url ).subscribe( (products: any[]) => {
                console.log(products);
                products.map( (singleProduct) => {
                    this.data.items.push(singleProduct);
                });
                this.calc();
            });
        }
    }
}
